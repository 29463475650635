import axios from "axios";
import { config } from "../../config";

export class WorkspacesService {
  public addNewWorkspace = async (data: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(serverUrl + "tenants/create", data);
      return response.data;
    } catch (error: any) {
      return { ...error };
    }
  };

  public uploadImage = async (form: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "tenants/upload-image",
        form
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };

  public updateLanguages = async (languages: any): Promise<any> => {
    try {
      const { serverUrl } = config;
      const response = await axios.post(
        serverUrl + "tenants/update-languages",
        languages
      );
      return response.data;
    } catch (error) {
      return {};
    }
  };
}
