import React, { createContext, useReducer } from "react";
import TodoListReducer from "../reducers/todoList.reducer";

const initialState: any = {
    todoList: [],
    pagination: {
        currentPage: 1,
        items: 15,
        totalPages: 1,
        total: 0,
    },
    counts: {
        pending: 0,
        completed: 0
    }
};

export const TodoListContext = createContext(initialState);

export const TodoListProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(TodoListReducer, initialState);

    function setTodoList(todoList: any[]) {
        dispatch({
            type: "SET_TODO_LIST",
            payload: { todoList }
        });
    }

    function setCounts(counts: any) {
        dispatch({
            type: "SET_COUNTS",
            payload: { counts }
        });
    }

    function setPagination(params: any) {
        dispatch({
            type: "SET_PAGINATION",
            payload: { params }
        });
    }

    const { todoList, pagination, counts } = state;

    return (
        <TodoListContext.Provider
            value={{
                todoList,
                pagination,
                counts,
                setCounts,
                setTodoList,
                setPagination,
            }}
        >
            {children}
        </TodoListContext.Provider>
    );
};