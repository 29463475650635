import React, { useState } from "react";
import "./workflow-todo.component.scoped.scss";
import { icons } from "../../statics/icons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { TodoService } from "../../services/todo/todo.service";
import { LangService } from "../../services/lang/lang.service";
import { showToastMsg } from "../../helpers";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";

export default function WorkflowTodo(props: any) {
    const { id, created_at, deadline, task, name, status } = props.data;
    const { index, deleteTodo, refresh, answer, isAuthorizedUser } = props;
    const [t] = useTranslation();
    const todoService = new TodoService();
    const langService = new LangService();

    const [open, setOpen] = useState(false);
    const locale = langService.getCurrentLang();
    const [deadlineDate, setDeadlineDate] = useState(deadline);
    const [dateChanged, setDateChanged] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const onDeadlineChangeHandler = async (date: any) => {
        setDateChanged(true);
        setDeadlineDate(date);
        const response = await todoService.update(id, { deadline: moment(date).format("YYYY-MM-DD") });
        if (response === 'Success') {
            showToastMsg('success', t('deadline updated successfully'));
        } else {
            showToastMsg('error', t('some thing went wrong'));
        }
        setDateChanged(false);
    }

    const completeTask = async () => {
        const response = await todoService.update(id, { status: true });
        if (response === 'Success') {
            showToastMsg('success', t('task completed successfully'));
            refresh();
        } else {
            showToastMsg('error', t('some thing went wrong'));
        }
    }

    const handleDeleteTodo = async (value: boolean) => {
        setShowConfirmModal(false);
        if (value) {
            const response = await todoService.delete(id);
            if (response === 'Success') {
                showToastMsg('success', t('task deleted successfully'));
                deleteTodo(index);
            } else {
                showToastMsg('error', t('some thing went wrong'));
            }
            // refresh();
        }
    }

    return (
        <div className="todo-row">
            <div className="left-row">
                <div className="info mb-2">
                    <p>{name}</p>
                </div>
                <div className="dates">
                    <p>{moment(created_at).format("DD.MM.YYYY")}</p>
                    <span>
                        {Math.ceil((new Date(deadlineDate).getTime() - new Date(created_at).getTime()) / (1000 * 3600 * 24))} {t("days")}
                    </span>
                    <p>
                        {moment(deadlineDate).format("DD.MM.YYYY")}
                        {status === 0 && answer === null && isAuthorizedUser &&
                            <>
                                <img src={icons.edit} alt="edit" onClick={() => setOpen(dateChanged ? false : true)} />
                                <div className="calender">
                                    <MuiPickersUtilsProvider
                                        utils={MomentUtils}
                                        libInstance={moment}
                                        locale={locale}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            disabled={dateChanged}
                                            variant="inline"
                                            inputVariant="filled"
                                            fullWidth
                                            format="DD/MM/YYYY"
                                            autoOk
                                            open={open}
                                            onOpen={() => setOpen(true)}
                                            onClose={() => setOpen(false)}
                                            value={deadlineDate}
                                            minDate={created_at ? created_at : undefined}
                                            onChange={(date: any): any => onDeadlineChangeHandler(date)}
                                        />
                                    </MuiPickersUtilsProvider>

                                </div>
                            </>}
                    </p>
                </div>
            </div>
            <div className="middle-row">
                <h6>{task}</h6>
            </div>
            <div className="right-row">
                {status === 0 && answer === null && isAuthorizedUser &&
                    <div className="action">
                        <div className="icon-wrap ">
                            <img src={icons.check} onClick={completeTask} alt="check" />
                        </div>
                        <div className="icon-wrap delete" onClick={() => setShowConfirmModal(true)}>
                            <img src={icons.deleteOrange} alt="delete" />
                        </div>
                    </div>
                }
            </div>
            {showConfirmModal && (
                <ConfirmDialog
                    show={showConfirmModal}
                    onClose={handleDeleteTodo}
                    dialogTitle={t("delete task title")}
                    dialogBody={t("delete task desc")}
                    confirmBtnText={t("finalize")}
                    cancelBtn={true}
                    showCheck={true}
                    dialogClassName='modal-lg'
                />
            )}
        </div>
    );
}