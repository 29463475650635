import React, { createContext, useReducer } from "react";
import LogsReducer from "../reducers/logs.reducer";
import { logsColumns } from "../../statics/logsColumns";

const initialState: any = {
    logs: [],
    columns: logsColumns,
    options: {
        withSort: true,
        sortDirection: 'asc',
        sortColumn: '',
    },
    pagination: {
        currentPage: 1,
        items: 15,
        totalPages: 1,
        total: 0,
    }
};

export const LogsContext = createContext(initialState);

export const LogsProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(LogsReducer, initialState);

    function setLogs(logs: any[]) {
        dispatch({
            type: "SET_LOGS",
            payload: { logs }
        });
    }

    function sort(params: any) {
        dispatch({
            type: "SORT_LOGS",
            payload: { params }
        });
    }

    function setPagination(params: any) {
        dispatch({
            type: "SET_PAGINATION",
            payload: { params }
        });
    }

    const { logs, columns, options, pagination } = state;

    return (
        <LogsContext.Provider
            value={{
                logs,
                columns,
                options,
                pagination,
                setLogs,
                setPagination,
                sort
            }}
        >
            {children}
        </LogsContext.Provider>
    );
};