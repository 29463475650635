import React from 'react';
import './todo.page.scoped.scss'
import { CommonService } from '../../services/common/common.service';
import { useTranslation } from 'react-i18next';
import HeaderComponent from "../../components/header/header.component";
import TodoContent from './components/todo-content/todo-content.component';
import { TodoListProvider } from '../../store/providers/todos.provider';

export default function TodoPage() {
  const commonService = new CommonService();
  const [t] = useTranslation();
  commonService.setTitle(t("to-do list"));

  return (
    <TodoListProvider>
      <HeaderComponent />
      <TodoContent />
    </TodoListProvider>
  );
}