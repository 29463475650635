import { SideNavItem } from "../types/sidenav-item";

import { icons } from "./icons";

export const sideNavItems: SideNavItem[] = [
  {
    name: "dashboard",
    title: "dashboard",
    path: "/dashboard",
    icon: icons.dashboardLight,
    iconHover: icons.dashboard,
    permissions: ["superAdmin", "user", "superUser", "auditor"],
  },
  {
    name: "cases",
    title: "cases",
    path: "/cases",
    icon: icons.reportsLight,
    iconHover: icons.reports,
    permissions: ["superAdmin", "user", "superUser", "auditor"],
  },
  {
    name: "todo-list",
    title: "todo-list",
    path: "/todo-list",
    icon: icons.todoLight,
    iconHover: icons.todo,
    permissions: ["superAdmin", "user", "superUser"]
  },
  // {
  //   name: "calender",
  //   title: "calender",
  //   path: "/calender",
  //   icon: icons.calenderLight,
  //   iconHover: icons.calender,
  //   permissions: ["superAdmin", "user", "superUser", "auditor"],
  // },
];
