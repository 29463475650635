export class Case {
  public AI?: any;
  public mainProcessor: string = "";
  public mainProcessorId: string = "";
  public amount: string = "";
  public amountUpdatedAdmin: string = "";
  public amountUpdatedAt: string = "";
  public assignee: string = "";
  public branch: string = "";
  public brief: string = "";
  public briefUpdateAdmin: string = "";
  public briefUpdatedAt: string = "";
  public category: string = "";
  public categoryUpdateAdmin: string = "";
  public categoryUpdatedAt: string = "";
  public client: string = "";
  public createdAt: string = "";
  public description: string = "";
  public descriptionUpdateAdmin: string = "";
  public descriptionUpdatedAt: string = "";
  public dueDate: Date = new Date();
  public endDate: string = "";
  public endDateUpdatedAdmin: string = "";
  public endDateUpdatedAt: string = "";
  public startDate: string = "";
  public startDateUpdatedAdmin: string = "";
  public startDateUpdatedAt: string = "";
  public id: number = 0;
  public internalId: string = "";
  public latestComment: string = "";
  public secretKey: string = "";
  public status: string = "";
  public statusId: string = "";
  public statusKey: string = "";
  public statusColor: string = "";
  public originalBrief: string = "";
  public originalDescription: string = "";
  public externalUsers: number = 0;
  public internalUsers: number = 0;
  public involvedCompany: string = "";
  public involvedCompanyUpdatedAdmin: string = "";
  public involvedCompanyUpdatedAt: string = "";
  public involvedDepartment: string = "";
  public involvedDepartmentUpdatedAdmin: string = "";
  public involvedDepartmentUpdatedAt: string = "";
  public involvedPersons: string = "";
  public involvedPersonsUpdatedAdmin: string = "";
  public involvedPersonsUpdatedAt: string = "";
  public labels: any[] = [];
  public labelsUpdateAdmin: string = "";
  public labelsUpdatedAt: string = "";
  public land: string = "";
  public landUpdateAdmin: string = "";
  public landUpdatedAt: string = "";
  public noticeFieldsLastUpdateDate: string = "";
  public optionalFieldsLastUpdateDate: string = "";
  public optionalFieldsEmpty: boolean = false;
  public originalAmount: string = "";
  public originalEndDate: string = "";
  public originalInvolvedCompany: string = "";
  public originalInvolvedDepartment: string = "";
  public originalInvolvedPersons: string = "";
  public originalLand: string = "";
  public originalRelationship: string = "";
  public originalStartDate: string = "";
  public relationship: string = "";
  public relationshipUpdatedAdmin: string = "";
  public relationshipUpdatedAt: string = "";
  public resolution: string = "";
  public resolutionComment: string = "";
  public duration: string = "";
}
