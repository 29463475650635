import React, { useContext } from "react";
import "./files-table.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@material-ui/lab";
import FilesTableRow from "../files-table-row/files-table-row.component";
import { File } from "../../types/file";
import { GlobalContext } from "../../store";

const FilesTable = (props: any) => {
  const [t] = useTranslation();
  const { user } = useContext(GlobalContext);

  return (
    <div className="files-table-card">
      {/* Table Columns */}
      <div className="files-table-headlines">
        <div className="d-flex align-items-center p-3">
          <div className="file-section">
            {props.loading ? (
              <Skeleton variant="text" height={20} />
            ) : (
              t("file")
            )}
          </div>
          <div className="user-section">
            {props.loading ? (
              <Skeleton variant="text" height={20} />
            ) : (
              t("uploaded")
            )}
          </div>
          <div className="action-section">
            {props.loading ? (
              <Skeleton variant="text" height={20} />
            ) : (
              t("ORIGIN")
            )}
          </div>
        </div>
      </div>
      {/* Table Rows */}
      <div className="files-container">
        {props.files.map((file: File, index: any) => {
          return (
            <FilesTableRow
              key={index}
              id={file.id}
              index={index}
              file={file.name}
              size={file.size}
              url={file.url}
              type={file.type?.toLowerCase()}
              user={file.user}
              date={file.createdAt}
              origin={file.origin}
              comments={file.comments || []}
              loading={props.loading}
              isAuthorizedUser={
                user.keycloakUserId === props.mainProcessorId ||
                user.keycloakUserId === file.userId ||
                user.type === "superAdmin" ||
                user.role === "coordinator"
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilesTable;
