import React, { useContext, useState } from "react";
import "./workflow-timeline.component.scoped.scss";
import { TimelineStatusesComponent } from "../timeline-statuses/timeline-statuses.component";
import { CaseContext } from "../../store";
import WorkflowTable from "../workflow-table/workflow-table.component";
import WorkflowChangeStatus from "../workflow-change-status/workflow-change-status.component";
import { CaseWorkflow } from "../../types/case-workflow";
import { useTranslation } from "react-i18next";

export const WorkflowTimelineComponent = () => {
  const {
    workflowLoading,
    caseDetails,
    caseStatuses,
    caseQuestions,
    completedStatusId,
  } = useContext(CaseContext);
  const [t] = useTranslation();
  // Changing status Buttons in every status (static).
  const setAvailableStatus = (statuses: any[]) => {
    if (statuses && statuses.length) {
      statuses[0].firstAvailableStatus = { ...statuses[1] };
      statuses[0].secondAvailableStatus = { ...statuses[2] };
      statuses[1].firstAvailableStatus = { ...statuses[2] };
      statuses[2].firstAvailableStatus = { ...statuses[1] };
      statuses[2].secondAvailableStatus = { ...statuses[3] };
    }
    return statuses;
  };
  const [timelineStatuses, setTimelineStatuses] = useState(
    setAvailableStatus(caseStatuses)
  );

  const isActiveStatus = (status: string): boolean => {
    return caseDetails.status === status;
  };

  const expandStatusComments = (expand: boolean, index: number): void => {
    const timelineStatusesCopy = [...timelineStatuses];
    timelineStatusesCopy[index].expand = expand;
    setTimelineStatuses(timelineStatusesCopy);
  };

  return (
    <div className="timeline">
      <div className="title">
        {t("checklist")} {caseDetails.category}
      </div>
      <div className="timeline-items">
        {caseQuestions.map((caseQuestion: CaseWorkflow, index: any) => {
          const questions: any[] =
            !timelineStatuses[index]?.expand && !workflowLoading
              ? []
              : caseQuestion.questions;
          return (
            <TimelineStatusesComponent
              key={index}
              activeStatus={isActiveStatus(caseQuestion.status)}
              lastStatus={index === timelineStatuses.length - 1}
              icon={timelineStatuses[index]?.icon}
              status={caseQuestion.status}
              date={caseQuestion.date}
              loading={workflowLoading}
              expand={timelineStatuses[index]?.expand}
              handleExpand={(value: boolean) =>
                expandStatusComments(value, index)
              }
              dimmed={timelineStatuses[index]?.dimmed}
              tab={"workflow"}
            >
              {questions && questions.length ? (
                <WorkflowTable
                  questions={questions}
                  loading={workflowLoading}
                  caseQuestionId={caseQuestion.id}
                  files={caseQuestion.files}
                />
              ) : null}
              {!workflowLoading &&
              questions &&
              questions.length &&
              caseDetails.statusId !== completedStatusId &&
              caseDetails.statusKey === caseQuestion.statusKey ? (
                <WorkflowChangeStatus
                  firstAvailableStatus={
                    timelineStatuses[index]?.firstAvailableStatus
                  }
                  secondAvailableStatus={
                    timelineStatuses[index]?.secondAvailableStatus
                  }
                  caseId={caseDetails.id}
                  showResults={index === 2}
                />
              ) : null}
            </TimelineStatusesComponent>
          );
        })}
      </div>
    </div>
  );
};
