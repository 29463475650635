import React, { useEffect, useState } from "react";
import "./reporter-fields.component.scoped.scss";

import { useTranslation } from "react-i18next";
import { Button, FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { SettingsService } from "../../../../services/settings/settings.service";
import "ace-builds";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-json5";
import { showToastMsg } from "../../../../helpers";
import { ReporterFieldsValues } from "../../../../types/reporter-configuration";

export default function ReporterFields(props: any) {
  const [t] = useTranslation();
  const settingsService: SettingsService = new SettingsService();

  const [fieldsConfiguration, setFieldsConfiguration] = useState(
    new ReporterFieldsValues()
  );
  const [originFieldsConfiguration, setOriginFieldsConfiguration] = useState(
    new ReporterFieldsValues()
  );
  const [fieldsConfigurationChanged, setFieldsConfigurationChanged] = useState(
    false
  );

  useEffect(() => {
    setFieldsConfiguration(props.data);
    setOriginFieldsConfiguration(props.data);
  }, [props]);
  /**
   * functions related to fields configuration
   */
  const updateFieldsConfiguration = async () => {
    setFieldsConfigurationChanged(false);
    const res = await settingsService.updateReporterConfiguration(
      fieldsConfiguration
    );
    if (res && res.message === "Success") {
      setOriginFieldsConfiguration(fieldsConfiguration);
      showToastMsg("success", t("configuration updated successfully"));
    } else {
      showToastMsg("error", t("Something went wrong!!"));
    }
  };

  const resetFieldsConfiguration = () => {
    setFieldsConfigurationChanged(false);
    setFieldsConfiguration(originFieldsConfiguration);
  };

  return (
    <>
      <div className="fields-customization">
        <div className="section-info">
          <h3>{t("manage fields")}</h3>
          <p>
            {t(
              "you can activate or deactivate some fields of the reporter page here"
            )}
          </p>
        </div>
        <div className="reporter-fields">
          <div className="form-group">
            <label>{t("country")}</label>
            <FormGroup>
              <FormControlLabel
                className="switch-step2"
                control={
                  <Switch
                    checked={fieldsConfiguration.country_visibility}
                    onChange={() => {
                      setFieldsConfiguration({
                        ...fieldsConfiguration,
                        country_visibility: !fieldsConfiguration.country_visibility,
                      });
                      setFieldsConfigurationChanged(true);
                    }}
                    color="primary"
                    name="country"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={t("activate field")}
              />
            </FormGroup>
          </div>
          <div className="form-group">
            <label>{t("optional informations")}</label>
            <FormGroup>
              <FormControlLabel
                className="switch-step2"
                control={
                  <Switch
                    checked={fieldsConfiguration.optional_fields_visibility}
                    onChange={() => {
                      setFieldsConfiguration({
                        ...fieldsConfiguration,
                        optional_fields_visibility: !fieldsConfiguration.optional_fields_visibility,
                      });
                      setFieldsConfigurationChanged(true);
                    }}
                    color="primary"
                    name="optional_fields"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={t("activate field")}
              />
            </FormGroup>
          </div>
          <div className="form-group">
            <label>{t("voice recording")}</label>
            <FormGroup>
              <FormControlLabel
                className="switch-step2"
                control={
                  <Switch
                    checked={fieldsConfiguration.voice_visibility}
                    onChange={() => {
                      setFieldsConfiguration({
                        ...fieldsConfiguration,
                        voice_visibility: !fieldsConfiguration.voice_visibility,
                      });
                      setFieldsConfigurationChanged(true);
                    }}
                    color="primary"
                    name="voice"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={t("activate field")}
              />
            </FormGroup>
          </div>

          {/*  */}
          <div className="form-group">
            <label>{t("report anonymously")}</label>
            <FormGroup>
              <FormControlLabel
                className="switch-step2"
                control={
                  <Switch
                    checked={fieldsConfiguration.report_anonymous}
                    onChange={() => {
                      setFieldsConfiguration({
                        ...fieldsConfiguration,
                        report_anonymous: !fieldsConfiguration.report_anonymous,
                      });
                      setFieldsConfigurationChanged(true);
                    }}
                    color="primary"
                    name="report_anonymous"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label={t("activate field")}
              />
            </FormGroup>
          </div>
          {/*  */}

          <div style={{ textAlign: "end" }}>
            <Button
              variant="outlined"
              color="primary"
              className="btn-cancel"
              onClick={resetFieldsConfiguration}
              disabled={!fieldsConfigurationChanged}
            >
              {t("cancel")}
            </Button>
            <Button
              className="btn-primary-theme btn-save"
              variant="contained"
              color="primary"
              onClick={() => updateFieldsConfiguration()}
              disabled={!fieldsConfigurationChanged}
            >
              <CheckIcon />
              {t("save changes")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
