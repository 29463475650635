export const createJson: any = {
  key: "create",
  value: `{
    	"choose country/company": {
            "EN": "Choose Country/Company",
            "DE": "Land/Unternehmen auswählen",
            "TR": "Choose Country/Company",
            "SR": "Choose Country/Company",
            "HR": "Choose Country/Company",
            "BS": "Choose Country/Company",
            "SK": "Choose Country/Company",
            "CS": "Choose Country/Company",
            "UK": "Choose Country/Company",
            "PL": "Choose Country/Company",
            "ES": "Choose Country/Company",
            "IT": "Choose Country/Company",
            "FR": "Choose Country/Company",
            "RU": "Choose Country/Company",
            "SQ": "Choose Country/Company",
            "RO": "Choose Country/Company",
            "BG": "Choose Country/Company"
        },
        "create": {
            "EN": "Create",
            "DE": "Anlegen",
            "TR": "Create",
            "SR": "Create",
            "HR": "Create",
            "BS": "Create",
            "SK": "Create",
            "CS": "Create",
            "UK": "Create",
            "PL": "Create",
            "ES": "Create",
            "IT": "Create",
            "FR": "Create",
            "RU": "Create",
            "SQ": "Create",
            "RO": "Create",
            "BG": "Create"
        },
        "track-case": {
            "EN": "Track case",
            "DE": "Meldung verfolgen",
            "TR": "Track case",
            "SR": "Track case",
            "HR": "Track case",
            "BS": "Track case",
            "SK": "Track case",
            "CS": "Track case",
            "UK": "Track case",
            "PL": "Track case",
            "ES": "Track case",
            "IT": "Track case",
            "FR": "Track case",
            "RU": "Track case",
            "SQ": "Track case",
            "RO": "Track case",
            "BG": "Track case"
        },
        "new-case": {
            "EN": "New Case",
            "DE": "Neue Meldung",
            "TR": "New Case",
            "SR": "New Case",
            "HR": "New Case",
            "BS": "New Case",
            "SK": "New Case",
            "CS": "New Case",
            "UK": "New Case",
            "PL": "New Case",
            "ES": "New Case",
            "IT": "New Case",
            "FR": "New Case",
            "RU": "New Case",
            "SQ": "New Case",
            "RO": "New Case",
            "BG": "New Case"
        },
        "welcome to the reporting channel": {
            "EN": "Welcome to the reporting channel",
            "DE": "Willkommen im Hinweisgeber:innensystem",
            "TR": "Welcome to the reporting channel",
            "SR": "Welcome to the reporting channel",
            "HR": "Welcome to the reporting channel",
            "BS": "Welcome to the reporting channel",
            "SK": "Welcome to the reporting channel",
            "CS": "Welcome to the reporting channel",
            "UK": "Welcome to the reporting channel",
            "PL": "Welcome to the reporting channel",
            "ES": "Welcome to the reporting channel",
            "IT": "Welcome to the reporting channel",
            "FR": "Welcome to the reporting channel",
            "RU": "Welcome to the reporting channel",
            "SQ": "Welcome to the reporting channel",
            "RO": "Welcome to the reporting channel",
            "BG": "Welcome to the reporting channel"
        },
        "welcome-title-one": {
            "EN": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "DE": "Mit diesem Hinweisgeber:innensystem bietet die Arbeiterkammer die Möglichkeit, Verstöße gegen Gesetze und interne Richtlinien einfach und auch anonym zu melden.",
            "TR": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "SR": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "HR": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "BS": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "SK": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "CS": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "UK": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "PL": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "ES": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "IT": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "FR": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "RU": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "SQ": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "RO": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines.",
            "BG": "With this internal reporting system, the company provides a channel for its employees, suppliers, service providers and customers to anonymously submit incidents against violations of laws and internal guidelines."
        },
        "welcome-title-two": {
            "EN": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "DE": "Alle übermittelten Informationen und Daten werden verschlüsselt auf sicheren IT-Systemen gespeichert und zur Bearbeitung nur explizit autorisierten Personen zugänglich gemacht.",
            "TR": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "SR": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "HR": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "BS": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "SK": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "CS": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "UK": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "PL": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "ES": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "IT": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "FR": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "RU": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "SQ": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "RO": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing.",
            "BG": "All transmitted information and data are encrypted, stored on secure IT systems and only made available to explicitly authorized persons for processing."
        },
        "welcome-title-three": {
            "EN": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "DE": "Das System zeichnet weder personenbezogene Daten noch Ihre IP-Adresse auf und wird von der GRC Experts GmbH, einer Tochtergesellschaft des Instituts für Interne Revision Österreich, betrieben, um ein Höchstmaß an Anonymität und Sicherheit garantieren zu können.",
            "TR": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "SR": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "HR": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "BS": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "SK": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "CS": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "UK": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "PL": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "ES": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "IT": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "FR": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "RU": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "SQ": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "RO": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely.",
            "BG": "When using the system, we do not record personal data or your IP addresses. Nevertheless, we recommend the TOR browser to use the system. This internet browser makes it possible to browse the internet anonymously, completely encrypted and securely."
        },
        "welcome-title-four": {
            "EN": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "DE": "Das System wird von der Compliance GmbH betrieben, um ein höchstmögliches Maß an Anonymität und Sicherheit gewährleisten zu können. Bitte stellen Sie sicher, dass Sie sich zum Zeitpunkt des Empfangs der Benachrichtigung außerhalb des Firmennetzes befinden.",
            "TR": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "SR": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "HR": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "BS": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "SK": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "CS": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "UK": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "PL": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "ES": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "IT": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "FR": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "RU": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "SQ": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "RO": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification.",
            "BG": "The system is operated by Compliance GmbH in order to be able to guarantee the highest possible level of anonymity and security. Please ensure that you are outside the company network when you receive the notification."
        },
        "where did the incident take place?": {
            "EN": "Where did the incident take place?",
            "DE": "In welcher Arbeiterkammer ereignete sich der Vorfall?",
            "TR": "Where did the incident take place?",
            "SR": "Where did the incident take place?",
            "HR": "Where did the incident take place?",
            "BS": "Where did the incident take place?",
            "SK": "Where did the incident take place?",
            "CS": "Where did the incident take place?",
            "UK": "Where did the incident take place?",
            "PL": "Where did the incident take place?",
            "ES": "Where did the incident take place?",
            "IT": "Where did the incident take place?",
            "FR": "Where did the incident take place?",
            "RU": "Where did the incident take place?",
            "SQ": "Where did the incident take place?",
            "RO": "Where did the incident take place?",
            "BG": "Where did the incident take place?"
        },
        "affected company": {
            "EN": "Affected company",
            "DE": "Betroffenes Unternehmen",
            "TR": "Affected company",
            "SR": "Affected company",
            "HR": "Affected company",
            "BS": "Affected company",
            "SK": "Affected company",
            "CS": "Affected company",
            "UK": "Affected company",
            "PL": "Affected company",
            "ES": "Affected company",
            "IT": "Affected company",
            "FR": "Affected company",
            "RU": "Affected company",
            "SQ": "Affected company",
            "RO": "Affected company",
            "BG": "Affected company"
        },
        "country": {
            "EN": "Country",
            "DE": "Land",
            "TR": "Country",
            "SR": "Country",
            "HR": "Country",
            "BS": "Country",
            "SK": "Country",
            "CS": "Country",
            "UK": "Country",
            "PL": "Country",
            "ES": "Country",
            "IT": "Country",
            "FR": "Country",
            "RU": "Country",
            "SQ": "Country",
            "RO": "Country",
            "BG": "Country"
        },
        "enter report": {
            "EN": "Enter Report",
            "DE": "Meldung Eingeben",
            "TR": "Enter Report",
            "SR": "Enter Report",
            "HR": "Enter Report",
            "BS": "Enter Report",
            "SK": "Enter Report",
            "CS": "Enter Report",
            "UK": "Enter Report",
            "PL": "Enter Report",
            "ES": "Enter Report",
            "IT": "Enter Report",
            "FR": "Enter Report",
            "RU": "Enter Report",
            "SQ": "Enter Report",
            "RO": "Enter Report",
            "BG": "Enter Report"
        },
        "please note that your information will only be forwarded to the selected company": {
            "EN": "Please note that your information will only be forwarded to the selected company.",
            "DE": "Bitte beachten Sie, dass Ihr Hinweis nur an das ausgewählte Unternehmen weitergeleitet wird.",
            "TR": "Please note that your information will only be forwarded to the selected company.",
            "SR": "Please note that your information will only be forwarded to the selected company.",
            "HR": "Please note that your information will only be forwarded to the selected company.",
            "BS": "Please note that your information will only be forwarded to the selected company.",
            "SK": "Please note that your information will only be forwarded to the selected company.",
            "CS": "Please note that your information will only be forwarded to the selected company.",
            "UK": "Please note that your information will only be forwarded to the selected company.",
            "PL": "Please note that your information will only be forwarded to the selected company.",
            "ES": "Please note that your information will only be forwarded to the selected company.",
            "IT": "Please note that your information will only be forwarded to the selected company.",
            "FR": "Please note that your information will only be forwarded to the selected company.",
            "RU": "Please note that your information will only be forwarded to the selected company.",
            "SQ": "Please note that your information will only be forwarded to the selected company.",
            "RO": "Please note that your information will only be forwarded to the selected company.",
            "BG": "Please note that your information will only be forwarded to the selected company."
        },
        "footer-text-btn1": {
            "EN": "Impressum",
            "DE": "Impressum",
            "TR": "Impressum",
            "SR": "Impressum",
            "HR": "Impressum",
            "BS": "Impressum",
            "SK": "Impressum",
            "CS": "Impressum",
            "UK": "Impressum",
            "PL": "Impressum",
            "ES": "Impressum",
            "IT": "Impressum",
            "FR": "Impressum",
            "RU": "Impressum",
            "SQ": "Impressum",
            "RO": "Impressum",
            "BG": "Impressum"
        },
        "footer-text-btn2": {
            "EN": "Privacy",
            "DE": "Datenschutz",
            "TR": "Privacy",
            "SR": "Privacy",
            "HR": "Privacy",
            "BS": "Privacy",
            "SK": "Privacy",
            "CS": "Privacy",
            "UK": "Privacy",
            "PL": "Privacy",
            "ES": "Privacy",
            "IT": "Privacy",
            "FR": "Privacy",
            "RU": "Privacy",
            "SQ": "Privacy",
            "RO": "Privacy",
            "BG": "Privacy"
        }
    }`,
};

export const recordJson: any = {
  key: "record",
  value: `{
    "record case": {
		"EN": "Record Case",
		"DE": "Record Case",
		"TR": "Record Case",
		"SR": "Record Case",
		"HR": "Record Case",
		"BS": "Record Case",
		"SK": "Record Case",
		"CS": "Record Case",
		"UK": "Record Case",
		"PL": "Record Case",
		"ES": "Record Case",
		"IT": "Record Case",
		"FR": "Record Case",
		"RU": "Record Case",
		"SQ": "Record Case",
		"RO": "Record Case",
		"BG": "Record Case"
	},
	"record": {
		"EN": "Record",
		"DE": "Erfassen",
		"TR": "Record",
		"SR": "Record",
		"HR": "Record",
		"BS": "Record",
		"SK": "Record",
		"CS": "Record",
		"UK": "Record",
		"PL": "Record",
		"ES": "Record",
		"IT": "Record",
		"FR": "Record",
		"RU": "Record",
		"SQ": "Record",
		"RO": "Record",
		"BG": "Record"
	},
	"description-title": {
		"EN": "Describe the incident",
		"DE": "Beschreiben Sie Ihre Meldung",
		"TR": "Describe the incident",
		"SR": "Describe the incident",
		"HR": "Describe the incident",
		"BS": "Describe the incident",
		"SK": "Describe the incident",
		"CS": "Describe the incident",
		"UK": "Describe the incident",
		"PL": "Describe the incident",
		"ES": "Describe the incident",
		"IT": "Describe the incident",
		"FR": "Describe the incident",
		"RU": "Describe the incident",
		"SQ": "Describe the incident",
		"RO": "Describe the incident",
		"BG": "Describe the incident"
	},
	"description-text": {
		"EN": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"DE": "Wenn Sie etwas wahrgenommen haben, von dem Sie annehmen, dass es gegen ein Gesetz oder interne Richtlinien verstößt, beschreiben Sie den Vorfall bitte so genau wie möglich.",
		"TR": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"SR": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"HR": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"BS": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"SK": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"CS": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"UK": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"PL": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"ES": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"IT": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"FR": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"RU": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"SQ": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"RO": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible.",
		"BG": "If you have noticed something that you believe violates a law or internal policy, please describe the incident as accurately as possible."
	},
	"description-label": {
		"EN": "Description of the incident or the improvement as precisely as possible",
		"DE": "Möglichst genaue Beschreibung des Vorfalls bzw. der Verbesserung",
		"TR": "Description of the incident or the improvement as precisely as possible",
		"SR": "Description of the incident or the improvement as precisely as possible",
		"HR": "Description of the incident or the improvement as precisely as possible",
		"BS": "Description of the incident or the improvement as precisely as possible",
		"SK": "Description of the incident or the improvement as precisely as possible",
		"CS": "Description of the incident or the improvement as precisely as possible",
		"UK": "Description of the incident or the improvement as precisely as possible",
		"PL": "Description of the incident or the improvement as precisely as possible",
		"ES": "Description of the incident or the improvement as precisely as possible",
		"IT": "Description of the incident or the improvement as precisely as possible",
		"FR": "Description of the incident or the improvement as precisely as possible",
		"RU": "Description of the incident or the improvement as precisely as possible",
		"SQ": "Description of the incident or the improvement as precisely as possible",
		"RO": "Description of the incident or the improvement as precisely as possible",
		"BG": "Description of the incident or the improvement as precisely as possible"
	},
	"relevant information on desc field": {
		"EN": "When you record an audio track, it will not be distorted.",
		"DE": "Wenn Sie eine Tonspur aufnehmen, dann wird diese nicht verzerrt.",
		"TR": "When you record an audio track, it will not be distorted.",
		"SR": "When you record an audio track, it will not be distorted.",
		"HR": "When you record an audio track, it will not be distorted.",
		"BS": "When you record an audio track, it will not be distorted.",
		"SK": "When you record an audio track, it will not be distorted.",
		"CS": "When you record an audio track, it will not be distorted.",
		"UK": "When you record an audio track, it will not be distorted.",
		"PL": "When you record an audio track, it will not be distorted.",
		"ES": "When you record an audio track, it will not be distorted.",
		"IT": "When you record an audio track, it will not be distorted.",
		"FR": "When you record an audio track, it will not be distorted.",
		"RU": "When you record an audio track, it will not be distorted.",
		"SQ": "When you record an audio track, it will not be distorted.",
		"RO": "When you record an audio track, it will not be distorted.",
		"BG": "When you record an audio track, it will not be distorted."
	},
	"category-title": {
		"EN": "Assign a category to the incident",
		"DE": "Ordnen Sie der Meldung eine Kategorie zu",
		"TR": "Assign a category to the incident",
		"SR": "Assign a category to the incident",
		"HR": "Assign a category to the incident",
		"BS": "Assign a category to the incident",
		"SK": "Assign a category to the incident",
		"CS": "Assign a category to the incident",
		"UK": "Assign a category to the incident",
		"PL": "Assign a category to the incident",
		"ES": "Assign a category to the incident",
		"IT": "Assign a category to the incident",
		"FR": "Assign a category to the incident",
		"RU": "Assign a category to the incident",
		"SQ": "Assign a category to the incident",
		"RO": "Assign a category to the incident",
		"BG": "Assign a category to the incident"
	},
	"category-text": {
		"EN": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"DE": "Um eine schnelle Bearbeitung zu ermöglichen, bitten wir Sie um Zuordnung Ihres Hinweises zu der möglichen Kategorie.",
		"TR": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"SR": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"HR": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"BS": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"SK": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"CS": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"UK": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"PL": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"ES": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"IT": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"FR": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"RU": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"SQ": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"RO": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category.",
		"BG": "In order to enable quick processing and to determine a suitable coordinator in our organization, we ask you to allocate the possible category."
	},
	"anonymity-title": {
		"EN": "Choose anonymity",
		"DE": "Anonymität",
		"TR": "Choose anonymity",
		"SR": "Choose anonymity",
		"HR": "Choose anonymity",
		"BS": "Choose anonymity",
		"SK": "Choose anonymity",
		"CS": "Choose anonymity",
		"UK": "Choose anonymity",
		"PL": "Choose anonymity",
		"ES": "Choose anonymity",
		"IT": "Choose anonymity",
		"FR": "Choose anonymity",
		"RU": "Choose anonymity",
		"SQ": "Choose anonymity",
		"RO": "Choose anonymity",
		"BG": "Choose anonymity"
	},
	"anonymity-text": {
		"EN": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"DE": "Eine Kommunikation mit anonymen Hinweisgeber:innen ist ausschließlich über diese Plattform nach Eingabe der Vorgangsnummer möglich. Wenn Sie Ihre Anonymität aufheben und Ihre Kontaktdaten eintragen, können wir Sie für Rückfragen auch per E-Mail kontaktieren.",
		"TR": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"SR": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"HR": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"BS": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"SK": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"CS": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"UK": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"PL": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"ES": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"IT": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"FR": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"RU": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"SQ": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"RO": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system.",
		"BG": "For certain reasons, you can remove your anonymity here. In this case, your name and your email address will be visible in the system."
	},
	"Share personal data": {
		"EN": "Share personal data",
		"DE": "Personenbezogene Daten freigeben",
		"TR": "Share personal data",
		"SR": "Share personal data",
		"HR": "Share personal data",
		"BS": "Share personal data",
		"SK": "Share personal data",
		"CS": "Share personal data",
		"UK": "Share personal data",
		"PL": "Share personal data",
		"ES": "Share personal data",
		"IT": "Share personal data",
		"FR": "Share personal data",
		"RU": "Share personal data",
		"SQ": "Share personal data",
		"RO": "Share personal data",
		"BG": "Share personal data"
	},
	"name-username-label": {
		"EN": "Name/Username",
		"DE": "Name/Username",
		"TR": "Name/Username",
		"SR": "Name/Username",
		"HR": "Name/Username",
		"BS": "Name/Username",
		"SK": "Name/Username",
		"CS": "Name/Username",
		"UK": "Name/Username",
		"PL": "Name/Username",
		"ES": "Name/Username",
		"IT": "Name/Username",
		"FR": "Name/Username",
		"RU": "Name/Username",
		"SQ": "Name/Username",
		"RO": "Name/Username",
		"BG": "Name/Username"
	},
	"email-label": {
		"EN": "Email address",
		"DE": "Email Adresse",
		"TR": "Email address",
		"SR": "Email address",
		"HR": "Email address",
		"BS": "Email address",
		"SK": "Email address",
		"CS": "Email address",
		"UK": "Email address",
		"PL": "Email address",
		"ES": "Email address",
		"IT": "Email address",
		"FR": "Email address",
		"RU": "Email address",
		"SQ": "Email address",
		"RO": "Email address",
		"BG": "Email address"
	},
	"relevant information on anonymity field": {
		"EN": "For the validation of your person we need your e-mail address.",
		"DE": "Für die Validierung Ihrer Person benötigen wir Ihre E-Mail Adresse.",
		"TR": "For the validation of your person we need your e-mail address.",
		"SR": "For the validation of your person we need your e-mail address.",
		"HR": "For the validation of your person we need your e-mail address.",
		"BS": "For the validation of your person we need your e-mail address.",
		"SK": "For the validation of your person we need your e-mail address.",
		"CS": "For the validation of your person we need your e-mail address.",
		"UK": "For the validation of your person we need your e-mail address.",
		"PL": "For the validation of your person we need your e-mail address.",
		"ES": "For the validation of your person we need your e-mail address.",
		"IT": "For the validation of your person we need your e-mail address.",
		"FR": "For the validation of your person we need your e-mail address.",
		"RU": "For the validation of your person we need your e-mail address.",
		"SQ": "For the validation of your person we need your e-mail address.",
		"RO": "For the validation of your person we need your e-mail address.",
		"BG": "For the validation of your person we need your e-mail address."
	},
	"optional-info-title": {
		"EN": "Optional information",
		"DE": "Weiterführende Informationen",
		"TR": "Optional information",
		"SR": "Optional information",
		"HR": "Optional information",
		"BS": "Optional information",
		"SK": "Optional information",
		"CS": "Optional information",
		"UK": "Optional information",
		"PL": "Optional information",
		"ES": "Optional information",
		"IT": "Optional information",
		"FR": "Optional information",
		"RU": "Optional information",
		"SQ": "Optional information",
		"RO": "Optional information",
		"BG": "Optional information"
	},
	"optional-info-text": {
		"EN": "You can submit the following more specific information to optimize processing.",
		"DE": "Um die Bearbeitung zu beschleunigen, können Sie uns genauere Informationen übermitteln.",
		"TR": "You can submit the following more specific information to optimize processing.",
		"SR": "You can submit the following more specific information to optimize processing.",
		"HR": "You can submit the following more specific information to optimize processing.",
		"BS": "You can submit the following more specific information to optimize processing.",
		"SK": "You can submit the following more specific information to optimize processing.",
		"CS": "You can submit the following more specific information to optimize processing.",
		"UK": "You can submit the following more specific information to optimize processing.",
		"PL": "You can submit the following more specific information to optimize processing.",
		"ES": "You can submit the following more specific information to optimize processing.",
		"IT": "You can submit the following more specific information to optimize processing.",
		"FR": "You can submit the following more specific information to optimize processing.",
		"RU": "You can submit the following more specific information to optimize processing.",
		"SQ": "You can submit the following more specific information to optimize processing.",
		"RO": "You can submit the following more specific information to optimize processing.",
		"BG": "You can submit the following more specific information to optimize processing."
	},
	"the fields are optional": {
		"EN": "The fields are optional.",
		"DE": "Diese Felder sind nicht verpflichtend auszufüllen.",
		"TR": "The fields are optional.",
		"SR": "The fields are optional.",
		"HR": "The fields are optional.",
		"BS": "The fields are optional.",
		"SK": "The fields are optional.",
		"CS": "The fields are optional.",
		"UK": "The fields are optional.",
		"PL": "The fields are optional.",
		"ES": "The fields are optional.",
		"IT": "The fields are optional.",
		"FR": "The fields are optional.",
		"RU": "The fields are optional.",
		"SQ": "The fields are optional.",
		"RO": "The fields are optional.",
		"BG": "The fields are optional."
	},
	"involved-company-label": {
		"EN": "Involved company",
		"DE": "Beteiligtes Unternehmen",
		"TR": "Involved company",
		"SR": "Involved company",
		"HR": "Involved company",
		"BS": "Involved company",
		"SK": "Involved company",
		"CS": "Involved company",
		"UK": "Involved company",
		"PL": "Involved company",
		"ES": "Involved company",
		"IT": "Involved company",
		"FR": "Involved company",
		"RU": "Involved company",
		"SQ": "Involved company",
		"RO": "Involved company",
		"BG": "Involved company"
	},
	"relation-label": {
		"EN": "Relation to the company",
		"DE": "Beziehung zur AK",
		"TR": "Relation to the company",
		"SR": "Relation to the company",
		"HR": "Relation to the company",
		"BS": "Relation to the company",
		"SK": "Relation to the company",
		"CS": "Relation to the company",
		"UK": "Relation to the company",
		"PL": "Relation to the company",
		"ES": "Relation to the company",
		"IT": "Relation to the company",
		"FR": "Relation to the company",
		"RU": "Relation to the company",
		"SQ": "Relation to the company",
		"RO": "Relation to the company",
		"BG": "Relation to the company"
	},
	"case is still active": {
		"EN": "Case is still active.",
		"DE": "Verhalten dauert an",
		"TR": "Case is still active.",
		"SR": "Case is still active.",
		"HR": "Case is still active.",
		"BS": "Case is still active.",
		"SK": "Case is still active.",
		"CS": "Case is still active.",
		"UK": "Case is still active.",
		"PL": "Case is still active.",
		"ES": "Case is still active.",
		"IT": "Case is still active.",
		"FR": "Case is still active.",
		"RU": "Case is still active.",
		"SQ": "Case is still active.",
		"RO": "Case is still active.",
		"BG": "Case is still active."
	},
	"start-date-label": {
		"EN": "Start date of the incident",
		"DE": "Startdatum",
		"TR": "Start date of the incident",
		"SR": "Start date of the incident",
		"HR": "Start date of the incident",
		"BS": "Start date of the incident",
		"SK": "Start date of the incident",
		"CS": "Start date of the incident",
		"UK": "Start date of the incident",
		"PL": "Start date of the incident",
		"ES": "Start date of the incident",
		"IT": "Start date of the incident",
		"FR": "Start date of the incident",
		"RU": "Start date of the incident",
		"SQ": "Start date of the incident",
		"RO": "Start date of the incident",
		"BG": "Start date of the incident"
	},
	"the start date should be less than the end date": {
		"EN": "The start date should be less than the end date",
		"DE": "Das Startdatum sollte weniger als das Enddatum sein",
		"TR": "The start date should be less than the end date",
		"SR": "The start date should be less than the end date",
		"HR": "The start date should be less than the end date",
		"BS": "The start date should be less than the end date",
		"SK": "The start date should be less than the end date",
		"CS": "The start date should be less than the end date",
		"UK": "The start date should be less than the end date",
		"PL": "The start date should be less than the end date",
		"ES": "The start date should be less than the end date",
		"IT": "The start date should be less than the end date",
		"FR": "The start date should be less than the end date",
		"RU": "The start date should be less than the end date",
		"SQ": "The start date should be less than the end date",
		"RO": "The start date should be less than the end date",
		"BG": "The start date should be less than the end date"
	},
	"end-date-label": {
		"EN": "End date of the incident",
		"DE": "Enddatum",
		"TR": "End date of the incident",
		"SR": "End date of the incident",
		"HR": "End date of the incident",
		"BS": "End date of the incident",
		"SK": "End date of the incident",
		"CS": "End date of the incident",
		"UK": "End date of the incident",
		"PL": "End date of the incident",
		"ES": "End date of the incident",
		"IT": "End date of the incident",
		"FR": "End date of the incident",
		"RU": "End date of the incident",
		"SQ": "End date of the incident",
		"RO": "End date of the incident",
		"BG": "End date of the incident"
	},
	"invalid date format": {
		"EN": "Invalid Date Format",
		"DE": "Ungültiges Datumsformat",
		"TR": "Invalid Date Format",
		"SR": "Invalid Date Format",
		"HR": "Invalid Date Format",
		"BS": "Invalid Date Format",
		"SK": "Invalid Date Format",
		"CS": "Invalid Date Format",
		"UK": "Invalid Date Format",
		"PL": "Invalid Date Format",
		"ES": "Invalid Date Format",
		"IT": "Invalid Date Format",
		"FR": "Invalid Date Format",
		"RU": "Invalid Date Format",
		"SQ": "Invalid Date Format",
		"RO": "Invalid Date Format",
		"BG": "Invalid Date Format"
	},
	"involved-people-label": {
		"EN": "Involved people",
		"DE": "Beteiligte Person/en",
		"TR": "Involved people",
		"SR": "Involved people",
		"HR": "Involved people",
		"BS": "Involved people",
		"SK": "Involved people",
		"CS": "Involved people",
		"UK": "Involved people",
		"PL": "Involved people",
		"ES": "Involved people",
		"IT": "Involved people",
		"FR": "Involved people",
		"RU": "Involved people",
		"SQ": "Involved people",
		"RO": "Involved people",
		"BG": "Involved people"
	},
	"involved-department-label": {
		"EN": "Involved department",
		"DE": "Betroffene Abteilung/en",
		"TR": "Involved department",
		"SR": "Involved department",
		"HR": "Involved department",
		"BS": "Involved department",
		"SK": "Involved department",
		"CS": "Involved department",
		"UK": "Involved department",
		"PL": "Involved department",
		"ES": "Involved department",
		"IT": "Involved department",
		"FR": "Involved department",
		"RU": "Involved department",
		"SQ": "Involved department",
		"RO": "Involved department",
		"BG": "Involved department"
	},
	"amount-damage-label": {
		"EN": "Amount of damage",
		"DE": "Schadenshöhe",
		"TR": "Amount of damage",
		"SR": "Amount of damage",
		"HR": "Amount of damage",
		"BS": "Amount of damage",
		"SK": "Amount of damage",
		"CS": "Amount of damage",
		"UK": "Amount of damage",
		"PL": "Amount of damage",
		"ES": "Amount of damage",
		"IT": "Amount of damage",
		"FR": "Amount of damage",
		"RU": "Amount of damage",
		"SQ": "Amount of damage",
		"RO": "Amount of damage",
		"BG": "Amount of damage"
	},
	"back": {
		"EN": "Back",
		"DE": "Zurück",
		"TR": "Back",
		"SR": "Back",
		"HR": "Back",
		"BS": "Back",
		"SK": "Back",
		"CS": "Back",
		"UK": "Back",
		"PL": "Back",
		"ES": "Back",
		"IT": "Back",
		"FR": "Back",
		"RU": "Back",
		"SQ": "Back",
		"RO": "Back",
		"BG": "Back"
	},
	"review": {
		"EN": "Review",
		"DE": "Überprüfen",
		"TR": "Review",
		"SR": "Review",
		"HR": "Review",
		"BS": "Review",
		"SK": "Review",
		"CS": "Review",
		"UK": "Review",
		"PL": "Review",
		"ES": "Review",
		"IT": "Review",
		"FR": "Review",
		"RU": "Review",
		"SQ": "Review",
		"RO": "Review",
		"BG": "Review"
	},
	"files": {
		"EN": "Files",
		"DE": "Dateien",
		"TR": "Files",
		"SR": "Files",
		"HR": "Files",
		"BS": "Files",
		"SK": "Files",
		"CS": "Files",
		"UK": "Files",
		"PL": "Files",
		"ES": "Files",
		"IT": "Files",
		"FR": "Files",
		"RU": "Files",
		"SQ": "Files",
		"RO": "Files",
		"BG": "Files"
	},
	"you can't use more than 50 characters in this field": {
		"EN": "You can't use more than 50 characters in this field",
		"DE": "Sie können nicht mehr als 50 Zeichen in diesem Feld verwenden",
		"TR": "You can't use more than 50 characters in this field",
		"SR": "You can't use more than 50 characters in this field",
		"HR": "You can't use more than 50 characters in this field",
		"BS": "You can't use more than 50 characters in this field",
		"SK": "You can't use more than 50 characters in this field",
		"CS": "You can't use more than 50 characters in this field",
		"UK": "You can't use more than 50 characters in this field",
		"PL": "You can't use more than 50 characters in this field",
		"ES": "You can't use more than 50 characters in this field",
		"IT": "You can't use more than 50 characters in this field",
		"FR": "You can't use more than 50 characters in this field",
		"RU": "You can't use more than 50 characters in this field",
		"SQ": "You can't use more than 50 characters in this field",
		"RO": "You can't use more than 50 characters in this field",
		"BG": "You can't use more than 50 characters in this field"
	},
	"character": {
		"EN": "Character",
		"DE": "Zeichen",
		"TR": "Character",
		"SR": "Character",
		"HR": "Character",
		"BS": "Character",
		"SK": "Character",
		"CS": "Character",
		"UK": "Character",
		"PL": "Character",
		"ES": "Character",
		"IT": "Character",
		"FR": "Character",
		"RU": "Character",
		"SQ": "Character",
		"RO": "Character",
		"BG": "Character"
	}
}`,
};

export const checkJson: any = {
  key: "check",
  value: `{
    "check case": {
		"EN": "Check Case",
		"DE": "Check Case",
		"TR": "Check Case",
		"SR": "Check Case",
		"HR": "Check Case",
		"BS": "Check Case",
		"SK": "Check Case",
		"CS": "Check Case",
		"UK": "Check Case",
		"PL": "Check Case",
		"ES": "Check Case",
		"IT": "Check Case",
		"FR": "Check Case",
		"RU": "Check Case",
		"SQ": "Check Case",
		"RO": "Check Case",
		"BG": "Check Case"
	},
	"check": {
		"EN": "Check",
		"DE": "Überprüfen",
		"TR": "Check",
		"SR": "Check",
		"HR": "Check",
		"BS": "Check",
		"SK": "Check",
		"CS": "Check",
		"UK": "Check",
		"PL": "Check",
		"ES": "Check",
		"IT": "Check",
		"FR": "Check",
		"RU": "Check",
		"SQ": "Check",
		"RO": "Check",
		"BG": "Check"
	},
	"check-title": {
		"EN": "Please check the details of your case",
		"DE": "Bitte überprüfen und ändern Sie gegenebenfalls Ihre Angaben bevor Sie die Meldung absenden",
		"TR": "Please check the details of your case",
		"SR": "Please check the details of your case",
		"HR": "Please check the details of your case",
		"BS": "Please check the details of your case",
		"SK": "Please check the details of your case",
		"CS": "Please check the details of your case",
		"UK": "Please check the details of your case",
		"PL": "Please check the details of your case",
		"ES": "Please check the details of your case",
		"IT": "Please check the details of your case",
		"FR": "Please check the details of your case",
		"RU": "Please check the details of your case",
		"SQ": "Please check the details of your case",
		"RO": "Please check the details of your case",
		"BG": "Please check the details of your case"
	},
	"check-text": {
		"EN": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"DE": "Stellen Sie sicher, dass bei den Angaben keine Rückschlüsse auf Ihre Person möglich sind, wenn Sie anonym bleiben wollen. Überprüfen Sie auch die Anhänge auf Ihre Anonymität. Wenn Sie sich bei manchen Details Ihrer Meldung nicht sicher sind, dann schreiben Sie das bitte dazu.",
		"TR": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"SR": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"HR": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"BS": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"SK": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"CS": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"UK": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"PL": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"ES": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"IT": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"FR": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"RU": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"SQ": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"RO": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess.",
		"BG": "Make sure that the information cannot be used to identify you personally. We take your advice very seriously and will initiate investigations. If you are unsure about the details of this message, please mark this part as a guess."
	},
	"check-information": {
		"EN": "Check information",
		"DE": "Angaben überprüfen",
		"TR": "Check information",
		"SR": "Check information",
		"HR": "Check information",
		"BS": "Check information",
		"SK": "Check information",
		"CS": "Check information",
		"UK": "Check information",
		"PL": "Check information",
		"ES": "Check information",
		"IT": "Check information",
		"FR": "Check information",
		"RU": "Check information",
		"SQ": "Check information",
		"RO": "Check information",
		"BG": "Check information"
	},
	"company": {
		"EN": "Company",
		"DE": "Unternehmen",
		"TR": "Company",
		"SR": "Company",
		"HR": "Company",
		"BS": "Company",
		"SK": "Company",
		"CS": "Company",
		"UK": "Company",
		"PL": "Company",
		"ES": "Company",
		"IT": "Company",
		"FR": "Company",
		"RU": "Company",
		"SQ": "Company",
		"RO": "Company",
		"BG": "Company"
	},
	"country": {
		"EN": "Country",
		"DE": "Land",
		"TR": "Country",
		"SR": "Country",
		"HR": "Country",
		"BS": "Country",
		"SK": "Country",
		"CS": "Country",
		"UK": "Country",
		"PL": "Country",
		"ES": "Country",
		"IT": "Country",
		"FR": "Country",
		"RU": "Country",
		"SQ": "Country",
		"RO": "Country",
		"BG": "Country"
	},
	"category": {
		"EN": "Category",
		"DE": "Kategorie",
		"TR": "Category",
		"SR": "Category",
		"HR": "Category",
		"BS": "Category",
		"SK": "Category",
		"CS": "Category",
		"UK": "Category",
		"PL": "Category",
		"ES": "Category",
		"IT": "Category",
		"FR": "Category",
		"RU": "Category",
		"SQ": "Category",
		"RO": "Category",
		"BG": "Category"
	},
	"name-username": {
		"EN": "Name/Username",
		"DE": "Name/Username",
		"TR": "Name/Username",
		"SR": "Name/Username",
		"HR": "Name/Username",
		"BS": "Name/Username",
		"SK": "Name/Username",
		"CS": "Name/Username",
		"UK": "Name/Username",
		"PL": "Name/Username",
		"ES": "Name/Username",
		"IT": "Name/Username",
		"FR": "Name/Username",
		"RU": "Name/Username",
		"SQ": "Name/Username",
		"RO": "Name/Username",
		"BG": "Name/Username"
	},
	"email-address": {
		"EN": "Email address",
		"DE": "Email Adresse",
		"TR": "Email address",
		"SR": "Email address",
		"HR": "Email address",
		"BS": "Email address",
		"SK": "Email address",
		"CS": "Email address",
		"UK": "Email address",
		"PL": "Email address",
		"ES": "Email address",
		"IT": "Email address",
		"FR": "Email address",
		"RU": "Email address",
		"SQ": "Email address",
		"RO": "Email address",
		"BG": "Email address"
	},
	"description": {
		"EN": "Description",
		"DE": "Vorfall",
		"TR": "Description",
		"SR": "Description",
		"HR": "Description",
		"BS": "Description",
		"SK": "Description",
		"CS": "Description",
		"UK": "Description",
		"PL": "Description",
		"ES": "Description",
		"IT": "Description",
		"FR": "Description",
		"RU": "Description",
		"SQ": "Description",
		"RO": "Description",
		"BG": "Description"
	},
	"start-date": {
		"EN": "Start date of the incident",
		"DE": "Startdatum",
		"TR": "Start date of the incident",
		"SR": "Start date of the incident",
		"HR": "Start date of the incident",
		"BS": "Start date of the incident",
		"SK": "Start date of the incident",
		"CS": "Start date of the incident",
		"UK": "Start date of the incident",
		"PL": "Start date of the incident",
		"ES": "Start date of the incident",
		"IT": "Start date of the incident",
		"FR": "Start date of the incident",
		"RU": "Start date of the incident",
		"SQ": "Start date of the incident",
		"RO": "Start date of the incident",
		"BG": "Start date of the incident"
	},
	"end-date": {
		"EN": "End date of the incident",
		"DE": "Enddatum",
		"TR": "End date of the incident",
		"SR": "End date of the incident",
		"HR": "End date of the incident",
		"BS": "End date of the incident",
		"SK": "End date of the incident",
		"CS": "End date of the incident",
		"UK": "End date of the incident",
		"PL": "End date of the incident",
		"ES": "End date of the incident",
		"IT": "End date of the incident",
		"FR": "End date of the incident",
		"RU": "End date of the incident",
		"SQ": "End date of the incident",
		"RO": "End date of the incident",
		"BG": "End date of the incident"
	},
	"involved-people": {
		"EN": "Involved people",
		"DE": "Beteiligte Person/en",
		"TR": "Involved people",
		"SR": "Involved people",
		"HR": "Involved people",
		"BS": "Involved people",
		"SK": "Involved people",
		"CS": "Involved people",
		"UK": "Involved people",
		"PL": "Involved people",
		"ES": "Involved people",
		"IT": "Involved people",
		"FR": "Involved people",
		"RU": "Involved people",
		"SQ": "Involved people",
		"RO": "Involved people",
		"BG": "Involved people"
	},
	"involved-department": {
		"EN": "Involved department",
		"DE": "Betroffene Abteilung/en",
		"TR": "Involved department",
		"SR": "Involved department",
		"HR": "Involved department",
		"BS": "Involved department",
		"SK": "Involved department",
		"CS": "Involved department",
		"UK": "Involved department",
		"PL": "Involved department",
		"ES": "Involved department",
		"IT": "Involved department",
		"FR": "Involved department",
		"RU": "Involved department",
		"SQ": "Involved department",
		"RO": "Involved department",
		"BG": "Involved department"
	},
	"involved-company": {
		"EN": "Involved company",
		"DE": "Beteiligtes Unternehmen",
		"TR": "Involved company",
		"SR": "Involved company",
		"HR": "Involved company",
		"BS": "Involved company",
		"SK": "Involved company",
		"CS": "Involved company",
		"UK": "Involved company",
		"PL": "Involved company",
		"ES": "Involved company",
		"IT": "Involved company",
		"FR": "Involved company",
		"RU": "Involved company",
		"SQ": "Involved company",
		"RO": "Involved company",
		"BG": "Involved company"
	},
	"amount-damage": {
		"EN": "Amount of damage",
		"DE": "Schadenshöhe",
		"TR": "Amount of damage",
		"SR": "Amount of damage",
		"HR": "Amount of damage",
		"BS": "Amount of damage",
		"SK": "Amount of damage",
		"CS": "Amount of damage",
		"UK": "Amount of damage",
		"PL": "Amount of damage",
		"ES": "Amount of damage",
		"IT": "Amount of damage",
		"FR": "Amount of damage",
		"RU": "Amount of damage",
		"SQ": "Amount of damage",
		"RO": "Amount of damage",
		"BG": "Amount of damage"
	},
	"relation": {
		"EN": "Relation to the company",
		"DE": "Beziehung zur AK",
		"TR": "Relation to the company",
		"SR": "Relation to the company",
		"HR": "Relation to the company",
		"BS": "Relation to the company",
		"SK": "Relation to the company",
		"CS": "Relation to the company",
		"UK": "Relation to the company",
		"PL": "Relation to the company",
		"ES": "Relation to the company",
		"IT": "Relation to the company",
		"FR": "Relation to the company",
		"RU": "Relation to the company",
		"SQ": "Relation to the company",
		"RO": "Relation to the company",
		"BG": "Relation to the company"
	},
	"back": {
		"EN": "Back",
		"DE": "Zurück",
		"TR": "Back",
		"SR": "Back",
		"HR": "Back",
		"BS": "Back",
		"SK": "Back",
		"CS": "Back",
		"UK": "Back",
		"PL": "Back",
		"ES": "Back",
		"IT": "Back",
		"FR": "Back",
		"RU": "Back",
		"SQ": "Back",
		"RO": "Back",
		"BG": "Back"
	},
	"finish": {
		"EN": "Finish",
		"DE": "Abschließen",
		"TR": "Finish",
		"SR": "Finish",
		"HR": "Finish",
		"BS": "Finish",
		"SK": "Finish",
		"CS": "Finish",
		"UK": "Finish",
		"PL": "Finish",
		"ES": "Finish",
		"IT": "Finish",
		"FR": "Finish",
		"RU": "Finish",
		"SQ": "Finish",
		"RO": "Finish",
		"BG": "Finish"
	},
	"dialog-confirm-case-title": {
		"EN": "Thank you very much for your comment!",
		"DE": "Vielen Dank für Ihren Hinweis!",
		"TR": "Thank you very much for your comment!",
		"SR": "Thank you very much for your comment!",
		"HR": "Thank you very much for your comment!",
		"BS": "Thank you very much for your comment!",
		"SK": "Thank you very much for your comment!",
		"CS": "Thank you very much for your comment!",
		"UK": "Thank you very much for your comment!",
		"PL": "Thank you very much for your comment!",
		"ES": "Thank you very much for your comment!",
		"IT": "Thank you very much for your comment!",
		"FR": "Thank you very much for your comment!",
		"RU": "Thank you very much for your comment!",
		"SQ": "Thank you very much for your comment!",
		"RO": "Thank you very much for your comment!",
		"BG": "Thank you very much for your comment!"
	},
	"dialog-confirm-case-text": {
		"EN": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"DE": "Wir nehmen diesen ernst und werden Untersuchungen einleiten. Ihre Meldung wird nur von explizit autorisierten Personen bearbeitet. Bitte beachten Sie:",
		"TR": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"SR": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"HR": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"BS": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"SK": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"CS": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"UK": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"PL": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"ES": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"IT": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"FR": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"RU": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"SQ": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"RO": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:",
		"BG": "We take it seriously and will initiate investigations. Your report will only be processed by explicitly authorized persons. Please note:"
	},
	"dialog-confirm-case-footer": {
		"EN": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"DE": "Nachdem Sie diese Meldung mit „Verstanden” abgeschlossen haben, können Sie Ihre Angaben nicht mehr ändern.",
		"TR": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"SR": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"HR": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"BS": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"SK": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"CS": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"UK": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"PL": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"ES": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"IT": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"FR": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"RU": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"SQ": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"RO": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information.",
		"BG": "After you have completed this notification by clicking 'Understood', you will no longer be able to change your information."
	},
	"dialog-confirm-case-point-one": {
		"EN": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"DE": "Als Hinweisgeber:in sind Sie vor Nachteilen im Zusammenhang mit dieser Meldung geschützt.",
		"TR": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"SR": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"HR": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"BS": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"SK": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"CS": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"UK": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"PL": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"ES": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"IT": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"FR": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"RU": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"SQ": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"RO": "As a whistleblower:in you are protected from disadvantages in connection with this report.",
		"BG": "As a whistleblower:in you are protected from disadvantages in connection with this report."
	},
	"dialog-confirm-case-point-two": {
		"EN": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"DE": "Offenkundig falsche oder irreführende Hinweise werden zurückgewiesen, können Schadenersatzansprüche begründen und gegebenenfalls gerichtlich verfolgt werden.",
		"TR": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"SR": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"HR": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"BS": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"SK": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"CS": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"UK": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"PL": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"ES": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"IT": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"FR": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"RU": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"SQ": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"RO": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action.",
		"BG": "Obviously false or misleading information will be rejected, may give rise to claims for damages and may be subject to legal action."
	},
	"dialog-confirm-case-approve": {
		"EN": "Approve",
		"DE": "Verstanden",
		"TR": "Approve",
		"SR": "Approve",
		"HR": "Approve",
		"BS": "Approve",
		"SK": "Approve",
		"CS": "Approve",
		"UK": "Approve",
		"PL": "Approve",
		"ES": "Approve",
		"IT": "Approve",
		"FR": "Approve",
		"RU": "Approve",
		"SQ": "Approve",
		"RO": "Approve",
		"BG": "Approve"
	}
}`,
};

export const downloadJson: any = {
  key: "download",
  value: `{
    "receive access data": {
		"EN": "Receive access data",
		"DE": "Zugangsdaten erhalten",
		"TR": "Receive access data",
		"SR": "Receive access data",
		"HR": "Receive access data",
		"BS": "Receive access data",
		"SK": "Receive access data",
		"CS": "Receive access data",
		"UK": "Receive access data",
		"PL": "Receive access data",
		"ES": "Receive access data",
		"IT": "Receive access data",
		"FR": "Receive access data",
		"RU": "Receive access data",
		"SQ": "Receive access data",
		"RO": "Receive access data",
		"BG": "Receive access data"
	},
	"download": {
		"EN": "Download",
		"DE": "Download",
		"TR": "Download",
		"SR": "Download",
		"HR": "Download",
		"BS": "Download",
		"SK": "Download",
		"CS": "Download",
		"UK": "Download",
		"PL": "Download",
		"ES": "Download",
		"IT": "Download",
		"FR": "Download",
		"RU": "Download",
		"SQ": "Download",
		"RO": "Download",
		"BG": "Download"
	},
	"title": {
		"EN": "Thank you for your report!",
		"DE": "Vielen Dank für Ihre Meldung!",
		"TR": "Thank you for your report!",
		"SR": "Thank you for your report!",
		"HR": "Thank you for your report!",
		"BS": "Thank you for your report!",
		"SK": "Thank you for your report!",
		"CS": "Thank you for your report!",
		"UK": "Thank you for your report!",
		"PL": "Thank you for your report!",
		"ES": "Thank you for your report!",
		"IT": "Thank you for your report!",
		"FR": "Thank you for your report!",
		"RU": "Thank you for your report!",
		"SQ": "Thank you for your report!",
		"RO": "Thank you for your report!",
		"BG": "Thank you for your report!"
	},
	"text-one": {
		"EN": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"DE": "Wir werden uns um den Sachverhalt kümmern und möchten gerne mit Ihnen in Kontakt bleiben, um Sie über unsere Erkenntnisse zu informieren und gegebenenfalls weitere Informationen von Ihnen zu erhalten.",
		"TR": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"SR": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"HR": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"BS": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"SK": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"CS": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"UK": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"PL": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"ES": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"IT": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"FR": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"RU": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"SQ": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"RO": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you.",
		"BG": "We will take care of the matter and would like to stay in contact with you in order to inform you about our findings and, if necessary, to receive further information from you."
	},
	"text-two": {
		"EN": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"DE": "Bitte schreiben Sie sich daher die folgenden Zugangsdaten zum gemeldeten Vorgang auf. Mit diesen können Sie sich im Menü „Meldung verfolgen“ anmelden und mit uns kommunizieren. Es gibt keine andere Möglichkeit, wie Sie mit uns ansonsten anonym zu diesem Sachverhalt in Kontakt treten können.",
		"TR": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"SR": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"HR": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"BS": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"SK": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"CS": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"UK": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"PL": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"ES": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"IT": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"FR": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"RU": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"SQ": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"RO": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise.",
		"BG": " Please write down the following access data for the reported case. You can use this to log in to the “Track case” menu and communicate with us. There is no other way you can anonymously contact us on this matter otherwise."
	},
	"text-important": {
		"EN": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"DE": "WICHTIG: Notieren Sie sich die Vorgangsnummer! Nur mit der Vorgangsnummer haben Sie später Zugriff auf Ihre Meldung.",
		"TR": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"SR": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"HR": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"BS": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"SK": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"CS": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"UK": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"PL": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"ES": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"IT": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"FR": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"RU": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"SQ": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"RO": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later.",
		"BG": "IMPORTANT: Please note the access data for your case! Only with this data you will have access to your case later."
	},
	"access-data": {
		"EN": "Access Data",
		"DE": "Vorgangsnummer",
		"TR": "Access Data",
		"SR": "Access Data",
		"HR": "Access Data",
		"BS": "Access Data",
		"SK": "Access Data",
		"CS": "Access Data",
		"UK": "Access Data",
		"PL": "Access Data",
		"ES": "Access Data",
		"IT": "Access Data",
		"FR": "Access Data",
		"RU": "Access Data",
		"SQ": "Access Data",
		"RO": "Access Data",
		"BG": "Access Data"
	},
	"download-access-data": {
		"EN": "Download Access Data",
		"DE": "Download Zugangsdaten",
		"TR": "Download Access Data",
		"SR": "Download Access Data",
		"HR": "Download Access Data",
		"BS": "Download Access Data",
		"SK": "Download Access Data",
		"CS": "Download Access Data",
		"UK": "Download Access Data",
		"PL": "Download Access Data",
		"ES": "Download Access Data",
		"IT": "Download Access Data",
		"FR": "Download Access Data",
		"RU": "Download Access Data",
		"SQ": "Download Access Data",
		"RO": "Download Access Data",
		"BG": "Download Access Data"
	},
	"download-pdf-file": {
		"EN": "Download report data as a pdf",
		"DE": "Download Meldung Datei als Pdf",
		"TR": "Download report data as a pdf",
		"SR": "Download report data as a pdf",
		"HR": "Download report data as a pdf",
		"BS": "Download report data as a pdf",
		"SK": "Download report data as a pdf",
		"CS": "Download report data as a pdf",
		"UK": "Download report data as a pdf",
		"PL": "Download report data as a pdf",
		"ES": "Download report data as a pdf",
		"IT": "Download report data as a pdf",
		"FR": "Download report data as a pdf",
		"RU": "Download report data as a pdf",
		"SQ": "Download report data as a pdf",
		"RO": "Download report data as a pdf",
		"BG": "Download report data as a pdf"
	},
	"close": {
		"EN": "Close",
		"DE": "Schliessen",
		"TR": "Close",
		"SR": "Close",
		"HR": "Close",
		"BS": "Close",
		"SK": "Close",
		"CS": "Close",
		"UK": "Close",
		"PL": "Close",
		"ES": "Close",
		"IT": "Close",
		"FR": "Close",
		"RU": "Close",
		"SQ": "Close",
		"RO": "Close",
		"BG": "Close"
	},
	"open-case": {
		"EN": "Open Case",
		"DE": "Meldung Öffnen",
		"TR": "Open Case",
		"SR": "Open Case",
		"HR": "Open Case",
		"BS": "Open Case",
		"SK": "Open Case",
		"CS": "Open Case",
		"UK": "Open Case",
		"PL": "Open Case",
		"ES": "Open Case",
		"IT": "Open Case",
		"FR": "Open Case",
		"RU": "Open Case",
		"SQ": "Open Case",
		"RO": "Open Case",
		"BG": "Open Case"
	},
	"dialog-open-case-title": {
		"EN": "Did you note the access data for your case?",
		"DE": "Haben Sie sich die Vorgangsnummer notiert?",
		"TR": "Did you note the access data for your case?",
		"SR": "Did you note the access data for your case?",
		"HR": "Did you note the access data for your case?",
		"BS": "Did you note the access data for your case?",
		"SK": "Did you note the access data for your case?",
		"CS": "Did you note the access data for your case?",
		"UK": "Did you note the access data for your case?",
		"PL": "Did you note the access data for your case?",
		"ES": "Did you note the access data for your case?",
		"IT": "Did you note the access data for your case?",
		"FR": "Did you note the access data for your case?",
		"RU": "Did you note the access data for your case?",
		"SQ": "Did you note the access data for your case?",
		"RO": "Did you note the access data for your case?",
		"BG": "Did you note the access data for your case?"
	},
	"dialog-open-case-text": {
		"EN": "ATTENTION: Note the process number!",
		"DE": "ACHTUNG: Vorgangsnummer notieren!",
		"TR": "ATTENTION: Note the process number!",
		"SR": "ATTENTION: Note the process number!",
		"HR": "ATTENTION: Note the process number!",
		"BS": "ATTENTION: Note the process number!",
		"SK": "ATTENTION: Note the process number!",
		"CS": "ATTENTION: Note the process number!",
		"UK": "ATTENTION: Note the process number!",
		"PL": "ATTENTION: Note the process number!",
		"ES": "ATTENTION: Note the process number!",
		"IT": "ATTENTION: Note the process number!",
		"FR": "ATTENTION: Note the process number!",
		"RU": "ATTENTION: Note the process number!",
		"SQ": "ATTENTION: Note the process number!",
		"RO": "ATTENTION: Note the process number!",
		"BG": "ATTENTION: Note the process number!"
	},
	"dialog-open-case-yes": {
		"EN": "Yes",
		"DE": "Ja",
		"TR": "Yes",
		"SR": "Yes",
		"HR": "Yes",
		"BS": "Yes",
		"SK": "Yes",
		"CS": "Yes",
		"UK": "Yes",
		"PL": "Yes",
		"ES": "Yes",
		"IT": "Yes",
		"FR": "Yes",
		"RU": "Yes",
		"SQ": "Yes",
		"RO": "Yes",
		"BG": "Yes"
	}
}`,
};

export const pdfJson: any = {
  key: "PDF",
  value: `{
        "case-secret-key": {
            "EN": "Case Secret Key",
            "DE": "Gehäuse Geheimschlüssel"
        },
        "involved-people": {
            "EN": "Involved people",
            "DE": "Beteiligte Personen"
        },
        "involved-departments": {
            "EN": "Involved departments",
            "DE": "Beteiligte Abteilungen"
        },
        "category": {
            "EN": "Category",
            "DE": "Kategorie"
        },
        "branch": {
            "EN": "Branch",
            "DE": "Zweigstelle"
        },
        "country": {
            "EN": "Country",
            "DE": "Land"
        },
        "amount": {
            "EN": "Amount",
            "DE": "Betrag"
        },
        "ongoing-case": {
            "EN": "Ongoing case",
            "DE": "Laufender Fall"
        },
        "yes": {
            "EN": "Yes",
            "DE": "Ja"
        },
        "no": {
            "EN": "No",
            "DE": "Nein"
        },
        "involved-company": {
            "EN": "Involved company",
            "DE": "Beteiligtes Unternehmen"
        },
        "anoymous-reporter": {
            "EN": "Anoymous reporter",
            "DE": "Anoymer Reporter"
        },
        "reporter-name": {
            "EN": "Reporter name",
            "DE": "Name des Reporters"
        },
        "reporter-email": {
            "EN": "Reporter email",
            "DE": "E-Mail des Reporters"
        },
        "relation": {
            "EN": "Relation",
            "DE": "Beziehung"
        },
        "start-date": {
            "EN": "Start date",
            "DE": "Datum des Beginns"
        },
        "end-date": {
            "EN": "End date",
            "DE": "Datum des Endes"
        },
        "description": {
            "EN": "Description",
            "DE": "Beschreibung"
        }
    }`,
};

export const caseJson: any = {
  key: "case",
  value: `{
        "case doesn't exist": {
            "EN": "case doesn't exist", 
            "DE": "Meldung existiert nicht"
        },
        "status": {
            "EN": "Status", 
            "DE": "Status"
        },
        "category": {
            "EN": "Category", 
            "DE": "Kategorie"
        },
        "created-date": {
            "EN": "Created date", 
            "DE": "Erstellungsdatum"
        },
        "results": {
            "EN": "Results", 
            "DE": "Ergebnisse"
        },
        "results-comment": {
            "EN": "Result comment", 
            "DE": "Resultats Kommentar"
        },
        "companies": {
            "EN": "Companies", 
            "DE": "Unternehmen"
        },
        "country": {
            "EN": "Country", 
            "DE": "Land"
        },
        "description": {
            "EN": "Description", 
            "DE": "Beschreibung"
        },
        "communication": {
            "EN": "Communication", 
            "DE": "Kommunikation"
        },
        "communication-files": {
            "EN": "Communication & Files", 
            "DE": "Kommunikation & Dateien"
        },
        "comments": {
            "EN": "Comments", 
            "DE": "Kommentare"
        },
        "comment": {
            "EN": "Comment", 
            "DE": "Kommentar"
        },
        "communication-text": {
            "EN": "Files and optionally a description can be uploaded here.", 
            "DE": "Hier können Dateien und optional eine Beschreibung dazu hochgeladen werden."
        },
        "communication-label": {
            "EN": "Add file and description to communicate with clerk", 
            "DE": "Datei und Beschreibung hinzufügen um mit Sachbearbeiter zu kommunizieren"
        },
        "files": {
            "EN": "Files", 
            "DE": "Dateien"
        },
        "character": {
            "EN": "Character", 
            "DE": "Zeichen"
        },
        "submited-description": {
            "EN": "Submitted description", 
            "DE": "Eingereichte Beschreibung"
        },
        "optional-data": {
            "EN": "Optional information", 
            "DE": "Optionale Angaben"
        },
        "notice-information": {
            "EN": "Notice information", 
            "DE": "Hinweisgeberinformationen"
        },
        "no data available": {
            "EN": "No data available", 
            "DE": "Keine Daten vorhanden"
        },
        "involved-people": {
            "EN": "Involved people", 
            "DE": "Beteiligte Personen"
        },
        "estimated-amount-damage": {
            "EN": "Estimated amount of damage", 
            "DE": "Geschätzte Schadenshöhe"
        },
        "involved-department": {
            "EN": "Involved department", 
            "DE": "Beteiligte Abteilungen"
        },
        "duration": {
            "EN": "Duration", 
            "DE": "Dauer"
        },
        "involved-company": {
            "EN": "Involved company", 
            "DE": "Beteiligtes Unternehmen"
        },
        "relation": {
            "EN": "Relation", 
            "DE": "Beziehung"
        },
        "name of hint": {
            "EN": "Name of hint", 
            "DE": "Hinweisgeber name"
        },
        "note given email": {
            "EN": "Note given email", 
            "DE": "Hinweisgeber email"
        },
        "whistleblower": {
            "EN": "Whistleblower", 
            "DE": "Hinweisgeber"
        },
        "clerk": {
            "EN": "Clerk", 
            "DE": "Sachbearbeiter/in"
        },
        "at": {
            "EN": "at", 
            "DE": "um"
        },
        "upload files": {
            "EN": "Upload files", 
            "DE": "Dateien hochladen"
        },
        "release the file to upload": {
            "EN": "Release the file to upload it for communication", 
            "DE": "Datei loslassen um sie zur Kommunikation hochzuladen"
        },
        "show-all": {
            "EN": "Show all", 
            "DE": "alle"
        },
        "show-comments": {
            "EN": "comments",
            "DE": "Kommentare anzeigen"
        }
    }`,
};

export const trackJson: any = {
  key: "track",
  value: `{
        "case doesn't exist": {
            "EN": "Case doesn't exist", 
            "DE": "Meldung existiert nicht"
        },
        "thank you for staying in touch with us": {
            "EN": "Thank you for staying in touch with us!", 
            "DE": "Danke dass Sie mit uns in Kontakt bleiben!"
        },
        "identify yourself with your access data": {
            "EN": "Identify yourself with your access data", 
            "DE": "Identifizieren Sie sich mit Ihren Zugangsdaten"
        },
        "ticket number": {
            "EN": "Ticket number", 
            "DE": "Vorgangsnummer"
        },
        "continue": {
            "EN": "Continue", 
            "DE": "Weiter"
        }
    }`,
};
