import "./table-row.component.scoped.scss";
import React, { useState, useEffect } from "react";
import { TableRowProps } from "../../../types/table-row-props";
import { TableColumn } from "../../../types/table-column";
import { history } from "react-router-guard";
import { formatDate, showToastMsg } from "../../../helpers";
import { icons } from "../../../statics/icons";
import {
  withStyles,
  MenuItem,
  ListItemText,
  Menu,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { LabelRoundComponent } from "../../label-round/label-round.component";
import { UsersService } from "../../../services/users/users.service";
import { ConfirmDialog } from "../../../dialogs/confirm-dialog/confirm-dialog";
import { LocalStorageService } from "../../../services/local-storage/local-storage";
import { useTranslation } from "react-i18next";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  // @ts-ignore
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const TableRowComponent = (props: TableRowProps) => {
  const [t] = useTranslation();
  const { columns, data, clickableRow, refresh, selectedCase } = props;
  const localStorageService: LocalStorageService = new LocalStorageService();
  const usersService: UsersService = new UsersService();

  const [row, setRow]: any = useState(data);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogBody, setDialogBody] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const user = localStorageService.getData("user");

  const handleDialogClose = (value: any) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      if (dialogTitle === "confirm delete") {
        handleDeleteUser();
      } else if (dialogTitle === "confirm deactivate") {
        handleDeactivateUser();
      } else if (dialogTitle === "confirm activate") {
        handleReactivateUser();
      }
    }
    setDialogTitle("");
    setDialogBody("");
  };

  const deleteUser = () => {
    handleClose();
    setDialogTitle("confirm delete");
    setDialogBody("are you sure you want to delete the user");
    setShowConfirmModal(true);
  };

  const handleDeleteUser = async () => {
    const res = await usersService.delete(row.id);
    if (res && res.message === "deleted") {
      refresh("delete");
      showToastMsg("success", t("user deleted successfully"));
    } else if (res && res.message) {
      showToastMsg("error", t(res.message));
    }
  };

  const deactivateUser = () => {
    handleClose();
    setDialogTitle("confirm deactivate");
    setDialogBody("are you sure you want to deactivate the user");
    setShowConfirmModal(true);
  };

  const handleDeactivateUser = async () => {
    const res = await usersService.deactivate(row.id);
    if (res && res.message === "deactivated") {
      refresh();
      showToastMsg("success", t("user deactivated successfully"));
    }
  };

  const reactivateUser = () => {
    handleClose();
    setDialogTitle("confirm activate");
    setDialogBody("are you sure you want to activate the user");
    setShowConfirmModal(true);
  };

  const handleReactivateUser = async () => {
    const res = await usersService.activate(row.id);
    if (res && res.message === "activated") {
      refresh();
      showToastMsg("success", t("user activated successfully"));
    }
  };

  const inviteUser = async () => {
    await usersService.renewTokenValidity({
      keycloakUserId: row.keycloakUserId,
      email: row.email,
      name: row.name,
      tenantId: localStorageService.getData("tenantId"),
      link: `${window.location.origin}`,
    });
    showToastMsg("success", t("An email has been sent to the user"));
  };

  useEffect(() => {
    setRow(props.data);
    // eslint-disable-next-line
  }, [props.data]);

  const navigator = (type: string) => {
    history.push(type);
  };

  const tableNavigator = (event: any) => {
    event.stopPropagation();
    console.log(event.target.id);
    if (clickableRow && event.target.id !== "checkbox") {
      const [page, path] = clickableRow;
      const completePath = data[path];
      navigator(`${page}/${completePath}`);
    }
  };

  const handleClick = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={
        "table-row " +
        (row.isDueDate && row.statusKey !== "COMPLETED" ? "due-over" : "")
      }
      onClick={tableNavigator}
    >
      {columns.map((column: TableColumn, index: number) => {
        const { fieldName, secondaryValue }: any = column;
        return (
          <div
            className={
              "one-column" +
              (column.type === "actions"
                ? " actions"
                : column.type === "checkbox"
                ? " checkbox-column"
                : "")
            }
            style={{ minWidth: `${column.width || 15}%` }}
            key={index}
          >
            {column.type === "string" ? (
              <div className={column.value === "category" ? column.value : ""}>
                {row[fieldName]}
              </div>
            ) : column.type === "email" ? (
              <div className="user-cell">{row[fieldName]}</div>
            ) : column.type === "comment" ? (
              <>{user.group !== "external" && row[fieldName]}</>
            ) : column.type === "dueDate" ? (
              <div className="date">
                {row.isDueDate && row.statusKey !== "COMPLETED" && (
                  <img src="icons/alert.svg" alt="" />
                )}
                <div>{formatDate(row[fieldName], "DD.MM.YYYY")}</div>
              </div>
            ) : column.type === "statusDate" ? (
              <div className="date">
                <div>{formatDate(row[fieldName], "DD.MM.YYYY")}</div>
              </div>
            ) : column.type === "date" ? (
              <div className="date log">
                <div>{formatDate(row[fieldName], "DD.MM.YYYY")}</div>
              </div>
            ) : column.type === "time" ? (
              <div className="time">
                <div>{formatDate(row[fieldName], "HH:mm:ss")}</div>
              </div>
            ) : column.type === "key" ? (
              <div className="key">
                <div>{t(row[fieldName])}</div>
              </div>
            ) : column.type === "logID" ? (
              <div className="id log">{row.value[fieldName]}</div>
            ) : column.type === "logKey" ? (
              <div className="key">{t(row[fieldName], { obj: row.value })}</div>
            ) : column.type === "user" ? (
              <div className="user-col">
                <div className="profile-picture">
                  <img src={row["img"]} alt="" className="user-image" />
                </div>
                <div>
                  <div>{row[fieldName]}</div>
                  <div className="branch-name">{row[secondaryValue]}</div>
                </div>
              </div>
            ) : column.type === "client" ? (
              <>
                <div className="user-cell">{row[fieldName]}</div>
                <div className="branch-name">
                  {row.type === "superUser"
                    ? t("coordinator")
                    : (row.type === "auditor" ? t("auditor") : t("editor")) +
                      "/" +
                      t(row[secondaryValue])}
                </div>
              </>
            ) : column.type === "status" ? (
              row.type !== "admin" && (
                <div
                  className={
                    "status-col d-flex align-items-center justify-content-center " +
                    row[fieldName]
                  }
                >
                  {row[fieldName] === "invited" && (
                    <img src={icons.inviteWhite} alt="" className="mr-1" />
                  )}
                  {row[fieldName] === "verified" && (
                    <img src={icons.checkWhite} alt="" className="mr-1" />
                  )}
                  {row[fieldName] === "deactivated" && (
                    <img src={icons.closeWhite} alt="" className="mr-1" />
                  )}
                  <div className="status-cell">{t(row[fieldName])}</div>
                </div>
              )
            ) : column.type === "categories" ? (
              <div className="categories-col d-flex align-items-center">
                {row[fieldName].map((category: any, index: number) => (
                  <div className="category" key={index}>
                    {category && <LabelRoundComponent label={category} />}
                  </div>
                ))}
              </div>
            ) : column.type === "checkbox" ? (
              <div className="checkbox-global" id="checkbox">
                <Checkbox
                  id="checkbox"
                  checked={row.checked}
                  onChange={() => selectedCase(!row.checked, row)}
                  name="auditor"
                  size="small"
                />
              </div>
            ) : column.type === "actions" ? (
              row.type !== "admin" && (
                <div className="buttons">
                  {column.fieldName === "users" && (
                    <div>
                      <IconButton
                        onClick={handleClick}
                        style={{ padding: "5px" }}
                      >
                        <MoreVertIcon className="menu-btn" />
                      </IconButton>
                      <StyledMenu
                        // @ts-ignore
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {(row.status === "invited" ||
                          row.status === "deactivated") && (
                          <MenuItem onClick={deleteUser}>
                            <ListItemText primary={t("delete")} />
                          </MenuItem>
                        )}
                        {row.status === "verified" && (
                          <MenuItem onClick={deactivateUser}>
                            <ListItemText primary={t("deactivate")} />
                          </MenuItem>
                        )}
                        {row.status === "deactivated" && (
                          <MenuItem onClick={reactivateUser}>
                            <ListItemText primary={t("reactivate")} />
                          </MenuItem>
                        )}
                        {row.status === "invited" && (
                          <MenuItem onClick={inviteUser}>
                            <ListItemText primary={t("invite again")} />
                          </MenuItem>
                        )}
                      </StyledMenu>
                    </div>
                  )}
                </div>
              )
            ) : (
              <div className={column.type}>{row[fieldName]}</div>
            )}
          </div>
        );
      })}
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          onClose={handleDialogClose}
          dialogTitle={t(dialogTitle)}
          dialogBody={t(dialogBody)}
          confirmBtnText={t("submit")}
          cancelBtn={true}
        />
      )}
    </div>
  );
};
