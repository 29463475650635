import { Checkbox, CircularProgress, FormControlLabel } from "@material-ui/core";
import "./todo-wrapper.component.scoped.scss"
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapperService } from "../../../../services/mapper/mapper.service";
import { TodoService } from "../../../../services/todo/todo.service";
import { TodoListContext } from "../../../../store/providers/todos.provider";
import TodoRowComponent from "../todo-row/todo-row.component";
import { TodoFooterComponent } from "../todo-footer/todo-footer.component";
import { defaultItems } from "../../../../statics/pagination";

export default function TodoWrapperComponent() {
    const [t] = useTranslation();
    const todoService = new TodoService();
    const mapperService = new MapperService();

    const emptyObj = {};
    const emptyArr: any = [];

    const { todoList, setTodoList, pagination, setPagination, counts, setCounts } = useContext(TodoListContext);

    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState(emptyObj);
    const [pending, setPending] = useState(false);
    const [complete, setComplete] = useState(false);

    const getQuery = () => {
        return new URLSearchParams(window.location.search);
    }


    const fetchTodos = async (filter?: any) => {
        setLoading(true);
        setTodoList(emptyArr);
        const response = await todoService.list(filter);
        const { readyTodos, paginationObj, counts } = mapperService.mapTodoList(response);
        setCounts(counts);
        setPagination(paginationObj);
        setTodoList(readyTodos);
        setLoading(false);
    }

    const onFilter = (e: any) => {
        e.persist();
        const caseId = getQuery().get('caseId');
        const filterObj: any = {
            items: pagination.items,
            page: 1,
            caseId
        };

        if (e.target.checked) {
            if (e.target.name === 'pending') {
                setPending(e.target.checked);
                filterObj.status = 0;
                setParams(filterObj);
            }
            else if (e.target.name === 'complete') {
                setComplete(e.target.checked);
                filterObj.status = 1;
                setParams(filterObj);
            }
        } else {
            setPending(false);
            setComplete(false);
            setParams(filterObj);
        }
        fetchTodos(filterObj);
    }

    const paginate = (pagination: any) => {
        const { items, currentPage } = pagination;
        const filterObj = {
            ...params,
            items,
            page: currentPage,
        };
        setParams(filterObj);
        fetchTodos(filterObj);
    };

    useEffect(() => {
        const caseId = getQuery().get('caseId');
        const paginationParams = { items: defaultItems, page: 1, caseId };

        setParams(paginationParams);
        fetchTodos(paginationParams);
        // eslint-disable-next-line
    }, []);

    const refresh = () => {
        fetchTodos(params);
    }

    return (
        <div className="todo-wrapper">
            <div className="todo-head">
                <div className="info">
                    <h4 className="title">{t("overview of the created todos")}</h4>
                    <p className="sub-title">{pagination.total} ToDos ({counts.pending} {t("pending")} / {counts.completed} {t("completed")})</p>
                </div>
                <div className="action">
                    <FormControlLabel
                        className="checkbox-global"
                        control={
                            <Checkbox
                                onChange={onFilter}
                                name="pending"
                                size="small"
                                disabled={complete}
                                checked={pending} />
                        }
                        label={t("pending")}
                    />
                    <FormControlLabel
                        className="checkbox-global"
                        control={
                            <Checkbox
                                onChange={onFilter}
                                name="complete"
                                size="small"
                                disabled={pending}
                                checked={complete}
                            />
                        }
                        label={t("completed")} />
                </div>
            </div>
            <div className="todo-contain">
                {loading && todoList && !todoList.length && (
                    <div className="loader-area">
                        <CircularProgress />
                    </div>
                )}
                {todoList && todoList.length === 0 && !loading && (
                    <div className="no-result">{t("no data available")}</div>
                )}
                {todoList.map((row: any, index: number) => {
                    return (
                        <TodoRowComponent refresh={refresh} key={index} data={row} />
                    )
                })}
                {!loading && !!todoList.length && <TodoFooterComponent paginate={paginate} pagination={pagination} />}
            </div>
        </div>
    )
}