import React, { useState } from "react";
import "./workflow-file.component.scoped.scss";
import { icons } from "../../statics/icons";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { CasesService } from "../../services/cases/cases.service";
import { ConfirmDialog } from "../../dialogs/confirm-dialog/confirm-dialog";
import { useTranslation } from "react-i18next";
import { showToastMsg } from "../../helpers";

const WorkflowFile = (props: any) => {
  const {
    name,
    isAuthorizedUser,
    type,
    size,
    id,
    url,
    deleteFile,
    canDeleteFile,
  } = props;
  const [t] = useTranslation();
  const casesService = new CasesService();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const downloadFile = () => {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleClose = (value: any) => {
    setTimeout(() => {
      setShowConfirmModal(false);
    }, 0);
    if (value) {
      deleteQuestionFile();
    }
  };

  const deleteQuestionFile = async () => {
    const res = await casesService.deleteWorkflowFile(id);
    if (res.message === "Success") {
      showToastMsg("success", t("ASSIGNED_FILE_DELETED_SUCCESSFULLY"));
      deleteFile();
    }
  };

  return (
    <div className="timeline-file">
      <div className="file-container">
        <div className="file-icon" onClick={downloadFile}>
          <img src={icons[type]} alt="" />
        </div>
        <div className="file-info d-flex flex-column">
          <div className="file-name trim" onClick={downloadFile}>
            {name}
          </div>
          <div className="file-size">{size}</div>
        </div>
      </div>
      <div className="action">
        <div className="download-icon" onClick={downloadFile}>
          <img src={icons.downloadLight} alt="" />
        </div>
        {isAuthorizedUser && canDeleteFile && (
          <div
            className="delete-icon"
            onClick={() => setShowConfirmModal(true)}
          >
            <DeleteOutlineIcon />
          </div>
        )}
      </div>
      {showConfirmModal && (
        <ConfirmDialog
          show={showConfirmModal}
          onClose={handleClose}
          dialogTitle={t("confirm delete")}
          dialogBody={t("are you sure you want to delete the assigned file")}
          confirmBtnText={t("delete")}
          cancelBtn={true}
        />
      )}
    </div>
  );
};

export default WorkflowFile;
