export const Languages = [
  { id: 1, key: "DE", name: "Dutch", visibility: 1 },
  { id: 2, key: "EN", name: "English", visibility: 1 },
  { id: 3, key: "TR", name: "Turkish", visibility: 1 },
  { id: 4, key: "SR", name: "Serbian", visibility: 1 },
  { id: 5, key: "HR", name: "Croatian", visibility: 1 },
  { id: 6, key: "BS", name: "Bosnian", visibility: 1 },
  { id: 7, key: "SK", name: "Slovak", visibility: 1 },
  { id: 8, key: "CS", name: "Czech", visibility: 1 },
  { id: 9, key: "UK", name: "Ukrainian", visibility: 1 },
  { id: 10, key: "PL", name: "Polish", visibility: 1 },
  { id: 11, key: "ES", name: "Spanish", visibility: 1 },
  { id: 12, key: "IT", name: "Italian", visibility: 1 },
  { id: 13, key: "FR", name: "French", visibility: 1 },
  { id: 14, key: "RU", name: "Russian", visibility: 1 },
  { id: 15, key: "SQ", name: "Albanian", visibility: 1 },
  { id: 16, key: "RO", name: "Romanian", visibility: 1 },
  { id: 17, key: "BG", name: "Bulgarian", visibility: 1 },
];
