import React, { useContext, useState } from "react";
import "./workflow-add-todo.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { Button, TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Skeleton } from "@material-ui/lab";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import "moment/locale/de";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import { LangService } from "../../services/lang/lang.service";
import { CaseContext } from "../../store";
import { TodoService } from "../../services/todo/todo.service";
import { showToastMsg } from "../../helpers";
import { TodoForm } from "../../types/todo";
import { AddUserDialog } from "../../dialogs/add-user/add-user";

export default function WorkflowAddTodo(props: any) {
    const { questionId, updateTodoList } = props;
    const [t] = useTranslation();
    const langService: LangService = new LangService();
    const todoService: TodoService = new TodoService();
    const locale = langService.getCurrentLang();
    const dateToday = new Date();
    const emptyObj: any = {};
    moment.locale(locale);

    const { caseDetails } = useContext(CaseContext);

    const [todo, setTodo] = useState(new TodoForm());
    const [selectedUser, setSelectedUser] = useState(emptyObj);

    const [deadline, setDeadline]: any = useState(null);

    const [saving, setSaving] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);


    const handleDeadlineChange = async (date: Moment) => {
        setDeadline(date);
        setTodo({
            ...todo,
            deadline: (date && date.isValid() && !date.isBefore()) ? moment(date).format("YYYY-MM-DD") : ''
        })
    }

    const handleSelectedUser = (value: any) => {
        if (value.length) {
            setSelectedUser(value[0]);
            setTodo({
                ...todo,
                userId: value[0].id
            })
        }
    }

    const addTodo = async () => {
        setSaving(true);
        const data = { ...todo };
        data.questionId = questionId;
        data.caseId = caseDetails.id;
        const response = await todoService.add(data);
        if (response.message === 'Success') {
            showToastMsg('success', t('task added successfully'));
            data.id = response.id;
            data.status = 0;
            data.name = selectedUser.name;
            data.email = selectedUser.email;
            data.created_at = new Date();

            updateTodoList(data);
            setTodo(new TodoForm());
            setSelectedUser({});
            setDeadline(null);
        } else {
            showToastMsg('error', 'Some thing went wrong!!');
        }
        setSaving(false);
    }

    return (
        <>
            <div className="todo-content">
                <div className="info">
                    <div className="user-section mb-4" onClick={() => setShowUserModal(true)}>
                        {(selectedUser && selectedUser.name) ? selectedUser.name : t("add responsible person")}
                        <ExpandMoreIcon />
                    </div>
                    <div className="date-section">
                        {props.loading ?
                            (<Skeleton variant="text" height={35} />) :
                            (<MuiPickersUtilsProvider
                                utils={MomentUtils}
                                libInstance={moment}
                                locale={locale}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="DD/MM/YYYY"
                                    autoOk
                                    value={deadline}
                                    minDate={dateToday}
                                    onChange={(date: any): any => handleDeadlineChange(date)}
                                    className="date-picker-standard"
                                    invalidDateMessage=""
                                    minDateMessage=""
                                    label={t("add deadline")}
                                    keyboardIcon={<EventOutlinedIcon />}
                                />
                            </MuiPickersUtilsProvider>)
                        }
                    </div>
                </div>
                <div className="form">
                    <TextField
                        id="filled-multiline-flexible"
                        value={todo.task}
                        onChange={(e: any) => setTodo({ ...todo, task: e.target.value })}
                        variant="filled"
                        className="text-area-multiline w-100"
                        fullWidth={true}
                        rows={5}
                        multiline
                        placeholder={t("describe this task")}
                    />
                </div>
                <div className="action">
                    <Button
                        className="btn-primary-theme w-100"
                        variant="contained"
                        color="primary"
                        onClick={addTodo}
                        disabled={saving || (!todo.task || !todo.userId || !todo.deadline)}>
                        {t("create")}
                    </Button>
                </div>
            </div>
            <AddUserDialog
                show={showUserModal}
                onClose={() => setShowUserModal(false)}
                isEditable={true}
                isTodo={true}
                onSelectUser={handleSelectedUser}
                user={(selectedUser && selectedUser.id) ? selectedUser : null}
            />
        </>
    )
}
