import React, { useContext, useEffect, useRef, useState } from "react";
import "./workflow-comment.component.scoped.scss";
import { TextField } from "@material-ui/core";
import { icons } from "../../statics/icons";
import { TimelineDeleteCommentComponent } from "../timeline-delete-comment/timeline-delete-comment.component";
import { formatDate } from "../../helpers";
import { useTranslation } from "react-i18next";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext } from "../../store";

const WorkflowComment = (props: any) => {
  const [t] = useTranslation();
  const [comment, setComment] = useState(props.comment);
  const { caseDetails, completedStatusId } = useContext(CaseContext);
  const [textFieldState, setTextFieldState] = useState(false);
  const inputRef: any = useRef();
  const maxChar = 500;
  const casesService = new CasesService();

  const isValid = (comment: string = "") => {
    return comment.length > 0 && comment.length <= maxChar;
  };

  const handleSaveClick = async () => {
    if (isValid(comment)) {
      const payload = {
        comment: comment,
      };
      await casesService.updateWorkflowComment(props.id, payload);
      setTextFieldState(false);
    }
  };

  const handleEditClick = () => {
    setTextFieldState(true);
  };

  useEffect(() => {
    if (comment && textFieldState === true) {
      inputRef.current.focus();
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
      inputRef.current.setSelectionRange(comment.length, comment.length);
    }
  });

  return (
    <div className="comments-section w-100">
      <div className="timeline-user align-items-center">
        <div className="user-img">
          <img src={icons.user} alt="" />
        </div>
        <div className="user-info pl-3">
          <div className="user-name">{props.user}</div>
          <div className="comment-date">
            {formatDate(props.date, "dddd, DD.MM.YYYY")} {t("at")}{" "}
            {formatDate(props.date, "HH:mm")}
          </div>
        </div>
      </div>

      <div className="comment-content">
        {textFieldState ? (
          <TextField
            id="filled-multiline-flexible"
            multiline
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            variant="filled"
            className={"text-area-multiline w-100 " +
              (Number(props.index) % 2 === 0
                ? "input-white"
                : "")
            }
            label=""
            inputRef={inputRef}
          />
        ) : (
          <div>{comment}</div>
        )}
      </div>
      <div className="icons">
        {props.isAuthorizedUser &&
          caseDetails.statusId !== completedStatusId ? (
          <div className="icons-wrap">
            {textFieldState ? (
              <div className="edit-icon " onClick={handleSaveClick}>
                <img src={icons.check} alt="" />
              </div>
            ) : (
              <div className="edit-icon " onClick={handleEditClick}>
                <img src={icons.edit} alt="" />
              </div>
            )}
            <TimelineDeleteCommentComponent
              id={props.id}
              questionId={props.questionId}
              caseQuestionId={props.caseQuestionId}
              index={props.commentIndex}
              deleteComment={(index: number) => props.deleteComment(index)}
              tab="workflow"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WorkflowComment;
