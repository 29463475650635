import "./files-table-row.component.scoped.scss";
import React, { useContext, useState } from "react";
import { icons } from "../../statics/icons";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import { Skeleton } from "@material-ui/lab";
import { formatDate, turncate } from "../../helpers";
import { TimelineDeleteCommentComponent } from "../timeline-delete-comment/timeline-delete-comment.component";
import FilesTableComment from "../files-comment/files-comment.component";
import FilesTableAddComment from "../files-add-comment/files-add-comment.component";
import { CaseContext } from "../../store";

const FilesTableRow = (props: any) => {
  const [openComments, setOpenComments] = useState(false);
  const [t] = useTranslation();
  const [comments, setComments] = useState(props.comments);
  const { caseDetails, completedStatusId } = useContext(CaseContext);

  const handleOpenComments = () => {
    setOpenComments(!openComments);
  };

  const handleUpdateComments = (comment: string) => {
    const commentsCopy = [...comments];
    commentsCopy.push(comment);
    setComments(commentsCopy);
  };

  const downloadFile = () => {
    const a = document.createElement("a");
    a.href = props.url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="file-wrapper">
      <div className="d-flex align-items-center p-3">
        <div className="file-section">
          <div className="file-type-icon">
            {props.loading ? (
              <Skeleton
                variant="rect"
                height={30}
                width={30}
                style={{ marginRight: "5px" }}
              />
            ) : (
              <img src={icons[props.type]} alt="" onClick={downloadFile} />
            )}
          </div>
          <div className="file-name d-flex flex-column justify-content-center align-items-start">
            {props.loading ? (
              <Skeleton variant="text" height={25} width={245} />
            ) : (
              <div className="filename-text" onClick={downloadFile}>
                {turncate(props.file, 75)}
              </div>
            )}
            {props.loading ? (
              <Skeleton variant="text" height={25} width={50} />
            ) : (
              <div className="file-size">{props.size}</div>
            )}
          </div>
        </div>
        <div className="user-section">
          <div className="timeline-user align-items-center">
            {props.loading ? (
              <Skeleton variant="circle" height={30} width={30} />
            ) : (
              <div className="user-img">
                <img src={icons.user} alt="" />
              </div>
            )}
            <div className="user-info pl-3">
              {props.loading ? (
                <Skeleton variant="text" height={25} width={250} />
              ) : (
                <div className="user-name">{props.user}</div>
              )}
              {props.loading ? (
                <Skeleton variant="text" height={25} width={150} />
              ) : (
                <div className="comment-date">
                  {formatDate(props.date, "dddd, DD.MM.YYYY")} {t("at")}{" "}
                  {formatDate(props.date, "HH:mm")}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="action-section">
          <div className="origin timeline">
            {props.loading ? (
              <Skeleton variant="text" height={25} width={250} />
            ) : (
              <div className="file-origin">{t(props.origin)}</div>
            )}
          </div>

          <div className="icons">
            {props.loading ? (
              <div className="icons-wrap">
                <Skeleton
                  variant="circle"
                  height={30}
                  width={30}
                  style={{ marginRight: "15px" }}
                />
                <Skeleton
                  variant="circle"
                  height={30}
                  width={30}
                  style={{ marginRight: "15px" }}
                />
                <Skeleton variant="circle" height={30} width={30} />
              </div>
            ) : (
              <div className="icons-wrap">
                <div
                  className={`icon-wrap ${
                    openComments && comments.length ? "bg-yellow" : ""
                  }`}
                  onClick={handleOpenComments}
                >
                  <img
                    src={
                      openComments && comments.length
                        ? icons.commentOrange
                        : icons.comment
                    }
                    alt=""
                  />
                  {comments && comments.length ? (
                    <div
                      className={`icon-number ${
                        openComments ? "color-orange" : ""
                      }`}
                    >
                      {comments.length}
                    </div>
                  ) : null}
                </div>

                <div className="download-icon" onClick={downloadFile}>
                  <img src={icons.downloadLight} alt="" />
                </div>

                {props.isAuthorizedUser &&
                  caseDetails.statusId !== completedStatusId && (
                    <div className="delete-icon">
                      <TimelineDeleteCommentComponent
                        fileId={props.id}
                        tab="files"
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>

      {openComments &&
      (comments.length || caseDetails.statusId !== completedStatusId) ? (
        <div className="px-3 py-2">
          <div className="comments-wrapper">
            <div
              className={
                comments.length === 0
                  ? "comments-text vis-hidden mb-3"
                  : "comments-text mb-3"
              }
            >
              {t("comments")}:{" "}
            </div>

            <div className="d-flex flex-column w-100">
              {props.loading ? (
                <>
                  <Skeleton
                    variant="rect"
                    height={20}
                    width={20}
                    style={{ marginRight: "15px" }}
                  />
                  <Skeleton variant="text" height={37} width={30} />
                </>
              ) : (
                comments.map((comment: any, index: any) => {
                  return (
                    <FilesTableComment
                      comment={comment.content}
                      user={comment.user}
                      date={comment.createdAt}
                      key={index}
                      loading={props.loading}
                    />
                  );
                })
              )}
              {caseDetails.statusId !== completedStatusId && (
                <div className="add-comment-section">
                  <FilesTableAddComment
                    fileId={props.id}
                    updateComments={(newComment: string) =>
                      handleUpdateComments(newComment)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilesTableRow;
