import React, { useState } from "react";
import { icons } from "../../../../statics/icons";
import "./todo-row.component.scoped.scss";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { LangService } from "../../../../services/lang/lang.service";
import { TodoService } from "../../../../services/todo/todo.service";
import { showToastMsg } from "../../../../helpers";
import { history } from "react-router-guard";
import { ConfirmDialog } from "../../../../dialogs/confirm-dialog/confirm-dialog";

export default function TodoRowComponent(props: any) {
    const { id, internalId, created_at, deadline, question, task, name, email, caseId, status, finishedDate, finishedBy, viewCase } = props.data;
    const { refresh } = props;
    const [t] = useTranslation();
    const todoService = new TodoService();
    const langService = new LangService();
    const locale = langService.getCurrentLang();

    const [deadlineDate, setDeadlineDate] = useState(deadline);
    const [open, setOpen] = useState(false);
    const [dateChanged, setDateChanged] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);

    const onDeadlineChangeHandler = async (date: any) => {
        setDateChanged(true);
        setDeadlineDate(date);
        const response = await todoService.update(id, { deadline: moment(date).format("YYYY-MM-DD") });
        if (response === 'Success') {
            showToastMsg('success', t('deadline updated successfully'));
        } else {
            showToastMsg('error', t('some thing went wrong'));
        }
        setDateChanged(false);
    }

    const completeTask = async () => {
        const response = await todoService.update(id, { status: true });
        if (response === 'Success') {
            showToastMsg('success', t('task completed successfully'));
        } else {
            showToastMsg('error', t('some thing went wrong'));
        }
        refresh();
    }

    const openCase = () => {
        if (viewCase) {
            history.push(`/case/${caseId}`, '_blank');
        } else {
            setShowRequestModal(true);
        }
    }

    const handleClose = (value: any) => {
        setShowRequestModal(false);
        console.log(value)
    };

    return (
        <div className="content mb-4">
            <div className={"tag " + (status ? 'complete' : 'pending')} >
                {status ? t("task completed") : t("task pending")}
            </div>
            <div className="todo-row">
                <div className="left-row">
                    <div className="info mb-4">
                        <span>{t("case")}:</span>
                        {/* <Link to={`/case/${caseId}`} target="_blank">{internalId}</Link> */}
                        <span className="link" onClick={openCase}>{internalId}</span>
                    </div>
                    {status === 0 ?
                        <div className="dates">
                            <p>{moment(created_at).format("DD.MM.YYYY")}</p>
                            <span>{Math.ceil((new Date(deadlineDate).getTime() - new Date(created_at).getTime()) / (1000 * 3600 * 24))} {t("days")}</span>
                            <p>
                                {moment(deadlineDate).format("DD.MM.YYYY")}
                                {status === 0 && <>
                                    <img src={icons.edit} alt="edit" onClick={() => setOpen(dateChanged ? false : true)} />
                                    <div className="calender">
                                        <MuiPickersUtilsProvider
                                            utils={MomentUtils}
                                            libInstance={moment}
                                            locale={locale}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                disabled={dateChanged}
                                                variant="inline"
                                                inputVariant="filled"
                                                fullWidth
                                                format="DD/MM/YYYY"
                                                autoOk
                                                open={open}
                                                onOpen={() => setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                value={deadlineDate}
                                                minDate={created_at ? created_at : undefined}
                                                onChange={(date: any): any => onDeadlineChangeHandler(date)}
                                            />
                                        </MuiPickersUtilsProvider>

                                    </div>
                                </>}
                            </p>
                        </div> :
                        <>
                            <div className="info">
                                <span>{t("responsible person-s")}</span>
                                <p>{name}</p>
                            </div>
                            <div className="date mt-2">
                                <span>{t("created on")}</span>
                                <p>{moment(created_at).format("DD.MM.YYYY")}</p>
                            </div>
                        </>

                    }
                </div>
                <div className="middle-row">
                    <h6>{question}</h6>
                    <p>{task}</p>
                </div>
                <div className="right-row">
                    {status === 0 ?
                        <>
                            <div className="action">
                                {/* <div className="icon-wrap ">
                            <img src={icons.comment} alt="" />
                        </div> */}
                                <div className="icon-wrap ">
                                    <img src={icons.check} onClick={completeTask} alt="edit" />
                                </div>
                            </div>
                            <div className="info text-right">
                                <span>{t("responsible person-s")}</span>
                                <p>{name}</p>
                                <a href={`mailto:${email}`}>{email}</a>
                            </div>
                        </> :
                        <div>
                            <div className="date mb-2">
                                <span>{t("done on")}</span>
                                <p>{moment(finishedDate).format("DD.MM.YYYY, HH:MM")}</p>
                            </div>
                            <div className="info">
                                <span>{t("done by")}</span>
                                <p>{finishedBy}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {showRequestModal && (
                <ConfirmDialog
                    show={showRequestModal}
                    onClose={handleClose}
                    dialogTitle={t("ask for request to this case")}
                    dialogBody={t("")}
                    confirmBtnText={t("ok")}
                    cancelBtn={false}
                    showCheck={true}
                />
            )}
        </div>
    )
}