import React, { useContext, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./support-contacts.component.scoped.scss";
import { GlobalContext } from "../../store";
import { fileTypes } from "../../statics/file-types";
import { turncate } from "../../helpers";
import { FileSizes } from "../../statics/file-sizes";
import withReactContent from "sweetalert2-react-content";
import { alertToastConfig } from "../../statics/alert-config";
import Swal from "sweetalert2";
// import { WorkspacesService } from "../../services/workspaces/workspaces.service";

// const workspaceService: WorkspacesService = new WorkspacesService();

const SupportContacts = ({ nextTab }: any) => {
  const [t] = useTranslation();
  const inputFileRef = useRef(null);

  const {
    contactInfoForm,
    setContactInfoForm,
    reporterImage,
    setReporterImage,
    workspaceForm,
    setWorkspaceForm,
  } = useContext(GlobalContext);

  const handleChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    const obj: any = {};
    obj[name] = value;
    setContactInfoForm({ ...contactInfoForm, ...obj });
  };

  const handleCountryCodeChange = (event: any) => {
    const value = event.target.value;
    const name = event.target.name;
    const obj: any = {};
    obj[name] = value;
    setWorkspaceForm({ ...workspaceForm, ...obj });
  };

  const clickUpload = () => {
    const element: any = inputFileRef.current;
    element.click();
  };

  async function getImagePreview(file: File) {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const src = reader.result;
        resolve(src);
      };
      reader.readAsDataURL(file);
    });
  }

  const validateFiles = async (files: any[]) => {
    const errorsMsg: any[] = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);
      const fileName = file.name.split("." + fileType)[0];
      if (file.size > maxSize) {
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(
            fileName,
            20
          )}</div> is bigger than max ${fileType.toUpperCase()} file size (${
            maxSize / 1024 / 1000
          } MB)`
        );
      } else {
        if (fileType.includes("jpg") || fileType.includes("png")) {
          file.src = await getImagePreview(file);
          setReporterImage(file);
        } else {
          errorsMsg.push("Only jpg/jpeg/png file types are supported");
        }
      }
    }
    showAlertMsg(errorsMsg);
  };

  const onFileChange = async (e: any) => {
    await validateFiles(e.target.files);
    const element: any = inputFileRef.current;
    element.value = "";
  };

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.mp4.maxSize };
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        break;
    }
  };

  async function showAlertMsg(queues: any[]) {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  }

  const titles: string[] = [
    "+ 43",
    "+ 49",
    "+ 33",
    "+ 32",
    "+ 34",
    "+ 46",
    "+ 41",
    "+ 36",
    "+ 39",
    "+ 31",
  ];

  return (
    <div className="p-3">
      <h2 className="main-header">Create Compliance Admin & Support Account</h2>
      <div className="sec-header">
        Fill out some quick details about yourself.
      </div>

      <div className="section-name mb-3">Compliance Admin Contact</div>

      <div className="reporter-img-upload">
        <div className="image-upload my-3">
          <img
            src={
              reporterImage.src
                ? reporterImage.src
                : "/icons/onboarding/cameraIcon.svg"
            }
            alt="camera"
            className={reporterImage.src ? "image-full" : "camera-icon"}
          />
        </div>
        <input
          type="file"
          ref={inputFileRef}
          onChange={onFileChange}
          style={{ display: "none" }}
          multiple
          accept={fileTypes}
        />

        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme w-25 btn-password"
          onClick={clickUpload}
        >
          {t("Upload Picture")}
        </Button>
      </div>

      <div className="row mb-3 ">
        <div className="col-5">
          <TextField
            fullWidth
            name="internalAdminName"
            variant="filled"
            label={t("Full Name")}
            inputProps={{
              maxLength: 50,
            }}
            onChange={handleChange}
            value={contactInfoForm.internalAdminName}
          />
        </div>

        <div className="col-5">
          <TextField
            fullWidth
            name="internalAdminEmail"
            variant="filled"
            label={t("E-Mail")}
            inputProps={{
              maxLength: 50,
            }}
            onChange={handleChange}
            value={contactInfoForm.internalAdminEmail}
          />
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-2">
          <FormControl className="select-input select-grey w-100">
            <InputLabel id="title">{t("Code")}</InputLabel>
            <Select
              labelId="title"
              id="title-select"
              name="countryCode"
              IconComponent={ExpandMoreIcon}
              onChange={handleCountryCodeChange}
              // value={contactInfoForm.countryCode}
              value={workspaceForm.countryCode}
            >
              {titles.map((item: any, index: any) => {
                return (
                  <MenuItem value={item} key={index}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>

        <div className="col-3">
          <TextField
            className="w-100"
            label={t("Phone Number")}
            variant="filled"
            name="internalAdminNumber"
            onChange={handleChange}
            value={contactInfoForm.internalAdminNumber}
          />
        </div>
      </div>

      <div className="section-name mt-4 mb-3">Support Contact</div>

      <div className="row">
        <div className="col-5">
          <TextField
            fullWidth
            name="techQuestionsEmail"
            variant="filled"
            label={t("Technical Questions E-Mail")}
            inputProps={{
              maxLength: 50,
            }}
            onChange={handleChange}
            value={contactInfoForm.techQuestionsEmail}
          />
        </div>

        <div className="col-5">
          <TextField
            fullWidth
            name="organisationQuestionEmail"
            variant="filled"
            label={t("Organational Questions E-Mail")}
            inputProps={{
              maxLength: 50,
            }}
            onChange={handleChange}
            value={contactInfoForm.organisationQuestionEmail}
          />
        </div>
      </div>

      <div className="btns-wrapper mb-5">
        <Grid container item xs={12} spacing={2}>
          <Grid container item justify="flex-end">
            <Box width="240px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  //    const { loading, ...rest } = formik.values;
                  //    dispatch(setStepTwo(rest));
                  nextTab();
                }}
                disabled={
                  contactInfoForm.internalAdminNumber.length === 0 ||
                  contactInfoForm.internalAdminEmail.length === 0 ||
                  contactInfoForm.internalAdminName.length === 0 ||
                  contactInfoForm.techQuestionsEmail.length === 0 ||
                  contactInfoForm.organisationQuestionEmail.length === 0 ||
                  Object.keys(reporterImage).length === 0
                }
              >
                next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SupportContacts;
