export default (state: any, action: any) => {
    switch (action.type) {
        case "SET_TODO_LIST":
            return {
                ...state,
                todoList: action.payload.todoList,
            };
        case "SET_COUNTS":
            return {
                ...state,
                counts: action.payload.counts,
            };
        case "SET_PAGINATION":
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload.params.currentPage,
                    items: action.payload.params.items,
                    totalPages: action.payload.params.totalPages,
                    total: action.payload.params.total,
                }
            };
        default:
            return state;
    }
};