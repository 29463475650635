import { TableColumn } from "../types/table-column";

export const logsColumns: TableColumn[] = [
  {
    type: "logID",
    value: "WORKSPACE_ID",
    fieldName: "prefix",
    width: 10,
    component: "logs",
  },
  {
    type: "email",
    value: "Processor",
    fieldName: "editorEmail",
    width: 20,
    component: "logs",
  },
  {
    type: "logKey",
    value: "Action",
    fieldName: "key",
    width: 40,
    component: "logs",
  },
  {
    type: "date",
    value: "Date",
    fieldName: "created_at",
    width: 10,
    component: "logs",
  },
  {
    type: "time",
    value: "Time",
    fieldName: "created_at",
    width: 10,
    component: "logs",
  },
];
