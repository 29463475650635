import React from "react";
import "./todo-content.component.scoped.scss"
import TodoWrapperComponent from "../todo-wrapper/todo-wrapper.component";
import { useTranslation } from "react-i18next";

export default function TodoContent() {
    const [t] = useTranslation();


    return (
        <div className="todo-content">
            <div className="todo-title">
                <h1>{t("to-do list")}</h1>
            </div>
            <div className="todo-wrapper theme-container">
                <TodoWrapperComponent />
            </div>
        </div>
    )
}
