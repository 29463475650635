import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./onboarding-workspace.component.scoped.scss";
import { InputViewComponent } from "../input-view/input-view.component";
import { GlobalContext } from "../../store";
import { fileTypes } from "../../statics/file-types";
import { FileSizes } from "../../statics/file-sizes";
import { turncate } from "../../helpers";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { alertToastConfig } from "../../statics/alert-config";
import { config } from "../../config";
import { configurationRegex, domainRegex } from "../../statics/validators";
import { Languages } from "../../statics/languages";

const OnboardingWorkspace = ({ nextTab, prevTab }: any) => {
  const [t] = useTranslation();
  const {
    setWorkspaceForm,
    workspaceForm,
    workspaceImage,
    setWorkspaceImage,
  } = useContext(GlobalContext);
  const inputFileRef = useRef(null);
  const [configurationError, setConfigurationError] = useState(false);
  const [reporterError, setReporterError] = useState(false);
  const handleChange = (event: any) => {
    let value = event.target.value;
    if (event.target.name === "reporterUrl") {
      value = event.target.value.trim();
    }
    const name = event.target.name;
    const obj: any = {};
    obj[name] = value;
    setWorkspaceForm({ ...workspaceForm, ...obj });
  };

  const clickUpload = () => {
    const element: any = inputFileRef.current;
    element.click();
  };

  async function getImagePreview(file: File) {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const src = reader.result;
        resolve(src);
      };
      reader.readAsDataURL(file);
    });
  }

  const validateFiles = async (files: any[]) => {
    const errorsMsg: any[] = [];
    for (const file of files) {
      const { fileType, maxSize } = getFileInfo(file.type);
      const fileName = file.name.split("." + fileType)[0];
      if (file.size > maxSize) {
        errorsMsg.push(
          `<div class='alert-file-name'>${turncate(
            fileName,
            20
          )}</div> is bigger than max ${fileType.toUpperCase()} file size (${
            maxSize / 1024 / 1000
          } MB)`
        );
      } else {
        if (fileType.includes("jpg") || fileType.includes("png")) {
          file.src = await getImagePreview(file);
          setWorkspaceImage(file);
        } else {
          errorsMsg.push("Only jpg/jpeg/png file types are supported");
        }
      }
    }
    showAlertMsg(errorsMsg);
  };

  const onFileChange = async (e: any) => {
    await validateFiles(e.target.files);
    const element: any = inputFileRef.current;
    element.value = "";
  };

  const getFileInfo = (type: string): any => {
    switch (type) {
      case "image/jpeg":
        return { fileType: "jpg", maxSize: FileSizes.jpg.maxSize };
      case "image/png":
        return { fileType: "png", maxSize: FileSizes.png.maxSize };
      case "video/mp4":
        return { fileType: "mp4", maxSize: FileSizes.png.maxSize };
      case "application/pdf":
        return { fileType: "pdf", maxSize: FileSizes.pdf.maxSize };
      default:
        break;
    }
  };

  async function showAlertMsg(queues: any[]) {
    const MySwal = withReactContent(Swal);
    for (const message of queues) {
      await MySwal.fire({
        icon: "error",
        title: message,
        ...alertToastConfig,
      });
    }
  }

  return (
    <div>
      <h1 className="main-header ">Establish your workspace</h1>
      <div className="sec-header ">
        Give us more infos based on your selected company
      </div>

      <div className="section-name ">Workspace Name</div>

      <div className="py-3 mb-2">
        <TextField
          style={{ width: "320px" }}
          label={t("Workspace name")}
          variant="filled"
          name="workspace"
          value={workspaceForm.workspace}
          onChange={handleChange}
        />
      </div>

      <div className="py-3 mb-3">
        <TextField
          style={{ width: "320px" }}
          label={"Configuration Name"}
          variant="filled"
          name="configurationName"
          value={workspaceForm.configurationName}
          onChange={(e: any) => {
            handleChange(e);
            e.target.value
              ? setConfigurationError(!configurationRegex.test(e.target.value))
              : setConfigurationError(false);
          }}
          error={configurationError ? true : false}
        />
        {configurationError && (
          <p className="error-msg">
            It should be only numbers and small letters
          </p>
        )}
      </div>

      <div className="section-name">Logo</div>
      <div className="logo-upload">
        <div className="image-upload my-3">
          <img
            src={
              workspaceImage.src
                ? workspaceImage.src
                : "/icons/onboarding/cameraIcon.svg"
            }
            alt="camera"
            className={workspaceImage.src ? "image-full" : "camera-icon"}
          />
        </div>
        <input
          type="file"
          ref={inputFileRef}
          onChange={onFileChange}
          style={{ display: "none" }}
          multiple
          accept={fileTypes}
        />

        <Button
          variant="outlined"
          color="primary"
          className="btn-secondary-theme w-25 btn-password"
          onClick={clickUpload}
        >
          {t("Upload Picture")}
        </Button>
      </div>

      <div className="section-name mb-3">Reporter URL</div>

      <div className="reporter-url">
        <InputViewComponent
          value=""
          label="https://"
          width={75}
          color="white"
        />
        <TextField
          style={{ width: "280px", margin: "0 20px" }}
          label={t("Personal Part of URL")}
          variant="filled"
          name="reporterUrl"
          value={workspaceForm.reporterUrl}
          onChange={(e: any) => {
            handleChange(e);
            e.target.value
              ? setReporterError(!domainRegex.test(e.target.value))
              : setReporterError(false);
          }}
          error={reporterError ? true : false}
        />
        <InputViewComponent
          value=""
          label={`-${config.subDomain}.${config.domain}`}
          width={318}
          color="white"
        />
      </div>

      <div className="section-name py-3">Languages</div>

      <div className="languages mb-4">
        {Languages.map((item, index) => {
          return (
            <FormControlLabel
              key={index}
              style={{ marginBottom: "0px" }}
              disabled
              control={<Checkbox checked name={item.name} />}
              label={item.name}
            />
          );
        })}
      </div>

      <div className="btns-wrapper mb-5">
        <Grid container item xs={12} spacing={2}>
          <Grid container item justify="space-between">
            <Box>
              <Button
                variant="text"
                color="primary"
                fullWidth
                onClick={() => {
                  //    const { loading, ...rest } = formik.values;
                  //    dispatch(setStepTwo(rest));
                  prevTab?.();
                }}
              >
                previous
              </Button>
            </Box>
            <Box width="240px">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                  //    const { loading, ...rest } = formik.values;
                  //    dispatch(setStepTwo(rest));
                  nextTab();
                }}
                disabled={
                  workspaceForm.workspace.length === 0 ||
                  workspaceForm.reporterUrl.length === 0 ||
                  workspaceForm.configurationName.length === 0 ||
                  configurationError ||
                  reporterError ||
                  Object.keys(workspaceImage).length === 0
                }
              >
                next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default OnboardingWorkspace;
