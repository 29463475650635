import React, { useState } from "react";
import "./workflow-add-files.component.scoped.scss";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import "moment/locale/de";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CasesService } from "../../services/cases/cases.service";
import { showToastMsg } from "../../helpers";

export default function WorkflowAddFiles(props: any) {
  const [t] = useTranslation();
  const { updateQuestionFile, questionId, caseFiles } = props;
  const caseServices = new CasesService();

  const [fileId, setFileId] = useState(null);
  const [saving, setSaving] = useState(false);

  const submit = async () => {
    setSaving(true);
    const response = await caseServices.addQuestionFile(questionId, { fileId });
    if (response.data && response.data.id) {
      showToastMsg("success", t("file added successfully"));
      setFileId(null);
      updateQuestionFile(response.data);
    } else {
      showToastMsg("error", "Some thing went wrong!!");
    }
    setSaving(false);
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-4">
          <FormControl className="select-input select-grey w-100">
            <InputLabel id="title">{t("Files")}</InputLabel>
            <Select
              labelId="files"
              id="file-select"
              name="fileId"
              value={fileId ? fileId : null}
              onChange={(e: any) => setFileId(e.target.value)}
              IconComponent={ExpandMoreIcon}
              disabled={saving}
            >
              {caseFiles.map((item: any, index: any) => {
                return (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="col-2 action">
          <Button
            className="btn-primary-theme w-100"
            variant="contained"
            color="primary"
            onClick={submit}
            disabled={!fileId || saving}
          >
            {t("ADD")}
          </Button>
        </div>
      </div>
    </>
  );
}
