import React, { useState, useEffect } from "react";
import "./cases-filter.component.scoped.scss";
import { CasesService } from "../../services/cases/cases.service";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Popover,
  Typography,
  Box,
  Checkbox,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FilterList, Add } from "@material-ui/icons";
import { periodsRange } from "../../statics/periods";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

export type TableFilter = {
  field: string; // field key
  label: string; // field label
  value: string; // search value
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const emptySearchObj = {
  label: "",
  searchId: "",
};

export default function CasesFilterComponent(props: any) {
  const { searchableFields, onFiltersChange } = props;
  const [t] = useTranslation();
  const casesService = new CasesService();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;

  const emptyArr: any = [];
  const [selectedLabels, setSelectedLabels] = useState(emptyArr);
  const [filters, setFilters] = useState<TableFilter[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchObj, setSearchObj] = useState(searchableFields[0]);
  const [selectedFilterIds, setSelectedFilterIds] = useState<string[]>([]);

  const [filterList, setFilterList]: any = useState({
    statusId: [],
    branchId: [],
    categoryId: [],
    assigneeId: [],
    labelsIds: [],
    period: periodsRange,
  });

  async function fetchData() {
    const statusRes = await casesService.listStatuses();
    const categoriesRes = await casesService.listCategories();
    const branchesRes = await casesService.listBranches();
    const usersRes = await casesService.listClerks({});
    const labels = await casesService.casesLabels();
    usersRes.unshift({ id: "all", name: t("all") });
    statusRes.unshift({ id: "all", name: t("all") });
    categoriesRes.unshift({ id: "all", name: t("all") });
    branchesRes.unshift({ id: "all", name: t("all") });

    setFilterList({
      ...filterList,
      statusId: [...statusRes],
      branchId: [...branchesRes],
      categoryId: [...categoriesRes],
      assigneeId: [...usersRes],
      labelsIds: [...labels],
    });
  }

  const addFilter = async () => {
    if (searchValue) {
      // set new filters
      setFilters([
        ...filters,
        {
          field: searchObj.searchId,
          label: searchObj.label,
          value: searchValue,
        },
      ]);
      // selected filters for hide the used filters
      const newSelectedFilterIds = [...selectedFilterIds, searchObj.searchId];
      setSelectedFilterIds([...newSelectedFilterIds]);
      // clear search text
      setSearchValue("");
      // set next filter dropdown value from fields
      setSearchObj(
        searchableFields.find(
          (field: any) => !newSelectedFilterIds.includes(field.searchId)
        ) || emptySearchObj
      );
    }
  };

  const removeFilter = async (value: any) => {
    // REMOVE FILTER
    setFilters([...filters.filter((filter) => filter.field !== value.field)]);
    // selected filters for hide the used filters
    const newSelectedFilterIds = selectedFilterIds.filter(
      (selectedId) => selectedId !== value.field
    );
    setSelectedFilterIds([...newSelectedFilterIds]);
    // set next filter dropdown value from fields if all filters used and removed one of them.
    if (!searchObj || !searchObj.serchId) {
      setSearchObj(
        searchableFields.find(
          (field: any) => !newSelectedFilterIds.includes(field.searchId)
        )
      );
    }
  };

  const handleFilterChange = (event: any) => {
    setSearchValue("");
    setSearchObj(
      searchableFields.find(
        (field: any) => field.searchId === event.target.value
      )
    );
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let payload: { [key: string]: string } = {};
    filters.map((filter) => {
      if (filter.field === "period") {
        const periodRange = periodsRange.find(
          (item) => item.id === filter.value
        ).value;
        payload = { ...payload, ...periodRange };
      } else {
        payload[filter.field] = filter.value;
      }
      return true;
    });
    onFiltersChange(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const onchangelabels = (e: any, value: any) => {
    setSelectedLabels(value);
    const labelsIds = value.map((label: any) => label.id);
    setSearchValue(labelsIds.join(","));
  };

  return (
    <div className="theme-container cases-filters w-100">
      <div className="filter-btn-container">
        <Button
          aria-describedby={id}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
            setAnchorEl(event.currentTarget)
          }
          className="btn-primary-theme filter-btn"
          variant="contained"
          color="primary"
        >
          <FilterList />
          {t("filters")}{" "}
        </Button>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          className="filter-content p-3"
          style={{ width: "600px", maxWidth: "600px" }}
        >
          {filters.map((filter: any) => {
            return (
              <Box className="filter-item mb-3" key={filter.field}>
                <FormControl className="select-input w-100">
                  {filter.field === "labelsIds" ? (
                    <Autocomplete
                      multiple
                      id={searchObj.label}
                      options={[...(filterList[searchObj.searchId] || [])]}
                      disableCloseOnSelect
                      size="small"
                      disabled
                      value={selectedLabels}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} label={t("labels")} />
                      )}
                    />
                  ) : (
                    <>
                      <InputLabel id={filter.searchId}>
                        {filter.label}
                      </InputLabel>
                      <Select
                        labelId={filter.field}
                        id={filter.field}
                        value={filter.value}
                        name={filter.field}
                        disabled
                        IconComponent={ExpandMoreIcon}
                      >
                        {[...(filterList[filter.field] || [])].map(
                          (option: any, index: number) => (
                            <MenuItem key={index} value={option.id}>
                              {filter.field === "period"
                                ? t(option.name)
                                : option.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </>
                  )}
                </FormControl>
                <FormControl className="select-input select-grey w-100">
                  <InputLabel id={filter.searchId}>{filter.label}</InputLabel>
                  <Select
                    labelId={filter.searchId}
                    id={filter.searchId}
                    value={filter.searchId}
                    name={filter.searchId}
                    IconComponent={ExpandMoreIcon}
                    disabled
                  >
                    {searchableFields.map((option: any) => (
                      <MenuItem key={option.searchId} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Box
                  onClick={() => removeFilter(filter)}
                  className="remove-filter"
                >
                  <DeleteOutlineIcon />
                </Box>
              </Box>
            );
          })}

          {filters.length !== searchableFields.length && (
            <>
              <Box className="filter-item">
                <FormControl className="select-input w-100">
                  {searchObj.searchId === "labelsIds" ? (
                    <Autocomplete
                      onChange={(event, value) => onchangelabels(event, value)}
                      multiple
                      id={searchObj.label}
                      options={[...(filterList[searchObj.searchId] || [])]}
                      disableCloseOnSelect
                      size="small"
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <FormControlLabel
                          className="checkbox-global"
                          control={
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                          }
                          label={option.name}
                        />
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label={t("SELECT_VALUE")} />
                      )}
                    />
                  ) : (
                    <>
                      <InputLabel id={searchObj.label}>
                        {t("SELECT_VALUE")}
                      </InputLabel>
                      <Select
                        labelId={searchObj.label}
                        id={searchObj.label}
                        value={searchValue}
                        name={searchObj.label}
                        onChange={(event: any) =>
                          setSearchValue(event.target.value)
                        }
                        IconComponent={ExpandMoreIcon}
                      >
                        {[...(filterList[searchObj.searchId] || [])].map(
                          (item: any) => (
                            <MenuItem key={item.id} value={item.id}>
                              {/* {item.name} */}
                              {searchObj.searchId === "period"
                                ? t(item.name)
                                : item.name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </>
                  )}
                </FormControl>

                <FormControl className="select-input select-grey w-100">
                  <InputLabel id={searchObj.searchId}>
                    {searchObj.label}
                  </InputLabel>
                  <Select
                    labelId={searchObj.label}
                    id={searchObj.searchId}
                    value={searchObj.searchId}
                    name={searchObj.searchId}
                    onChange={handleFilterChange}
                    IconComponent={ExpandMoreIcon}
                  >
                    {searchableFields.map(
                      (option: any) =>
                        !selectedFilterIds.includes(option.searchId) && (
                          <MenuItem
                            key={option.searchId}
                            value={option.searchId}
                          >
                            {t(option.label)}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}

          <Box
            display="flex"
            alignItems="center"
            marginTop={3}
            gridGap={10}
            style={{ cursor: "pointer" }}
            onClick={addFilter}
          >
            <Box className="cyrcle-icon">
              <Add />
            </Box>
            <Typography>{t("ADD_FILTER")}</Typography>
          </Box>
        </div>
      </Popover>
    </div>
  );
}
