import React, { useContext, useEffect } from "react";
import { splitUserName } from "../../helpers";
import { AuthService } from "../../services/auth/auth.service";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { GlobalContext } from "../../store";
import "./worksapces-navbar.compoenent.scoped.scss";
import { history } from "react-router-guard";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import io from "socket.io-client";
import { icons } from "../../statics/icons";

const localStorageService: LocalStorageService = new LocalStorageService();
const authService: AuthService = new AuthService();

function getFaviconEl() {
  return document.getElementById("favicon");
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: "#2a3638",
      fontSize: "13px",
    },
    arrow: {
      color: "#2a3638",
    },
  })
);

const WorksapcesNavbar = () => {
  const classes = useStyles();
  const { userTenants, setUser, user, setUserTenants, setEncodeStatus } = useContext(GlobalContext);

  const handleTenantClick = async (item: any) => {
    localStorageService.setData("tenantId", item.id);
    let user = await authService.account();

    const { firstName, lastName } = splitUserName(user.name);
    user = { ...user, firstName, lastName };

    localStorageService.setData("tenantName", item.name);
    localStorageService.setData("tenantIcon", item.logoUrl);
    localStorageService.setData("user", user);

    setUser(user);
    setEncodeStatus(item.encodeStatus);

    handleFavIco(item.name.toLowerCase(), item);
    history.replace("/temp");
    setTimeout(() => {
      history.replace("/dashboard");
    });
  };

  const handleFavIco = (name: string, item: any) => {
    const favicon: any = getFaviconEl();
    if (item.logoUrl) {
      favicon.href = item.logoUrl;
    } else {
      favicon.href = "/fav/moweex.png";
    }
  };

  const handlePlusBtnClick = () => {
    history.replace("/temp");
    setTimeout(() => {
      history.replace("/workspaces");
    });
  };

  const updateWorkspacesNotifications = (tenantId: any) => {
    const userTenantsCopy = [...userTenants];
    userTenantsCopy.forEach((item: any) => {
      if (item.id.toString() === tenantId) {
        item.notificationsNumber++;
      }
    });
    setUserTenants(userTenantsCopy);
  };

  const initSocket = () => {
    const socketIO = io("https://socket.api.gethuddly.com/", {
      transports: ["websocket", "xhr-polling"],
    });

    userTenants.forEach((tenant: any) => {
      socketIO.emit(
        "join",
        `compliance2b-${process.env.REACT_APP_STAGE}-${tenant.id}`
      );
    });

    socketIO.on("notification-update", (data: any) => {
      if (data.usersIds.includes(user.id)) {
        updateWorkspacesNotifications(data.tenantId);
      }
    });
  };

  useEffect(() => {
    if (user && user.type === "user" && userTenants.length > 0) {
      initSocket();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="workspaces-navbar">
      <div className="workspaces-navbar-wrapper">
        {userTenants.map((item: any, index: number) => {
          return (
            <Tooltip
              key={index}
              title={item.name}
              classes={classes}
              placement="right"
              background-color="primary"
              arrow
            >
              <div
                className={`worksapce-image ${item.id === localStorageService.getData("tenantId") ? "white-wrapper" : ""}`}
                onClick={handleTenantClick.bind(null, item)}>
                <img src={item.iconSrc} alt="" />
                {item.notificationsNumber > 0 && item.type === "user" && (
                  <div className="notifications-wrapper">
                    {item.notificationsNumber > 15 ? 15 : item.notificationsNumber}
                  </div>
                )}
              </div>
            </Tooltip>
          );
        })}
        {userTenants.length > 1 ? (
          <div onClick={handlePlusBtnClick} className="mt-1 mx-auto home-icon">
            <img src={icons.homeLight} alt="workspaces-icon" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WorksapcesNavbar;
