import { TextField, InputAdornment } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import React, { useState } from "react";
import { icons } from "../../statics/icons";
import "./workflow-add-comment.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { CasesService } from "../../services/cases/cases.service";

const WorkflowAddComment = (props: any) => {
  const [comment, setComment] = useState("");
  const [t] = useTranslation();
  const casesService = new CasesService();
  const [saving, setSaving] = React.useState(false);
  const handleChange = (event: any) => {
    const value = event?.target?.value;
    setComment(value);
  };
  const maxChar = 500;

  const isValid = (comment: string = "") => {
    return comment.length > 0 && comment.length <= maxChar;
  };

  const addComment = async () => {
    if (isValid(comment) && !saving) {
      setSaving(true);
      const res = await casesService.addWorkflowComment(props.questionId, {
        comment,
      });
      setSaving(false);
      if (res && res.comment) {
        props.updateComments(res.comment);
        setComment("");
      }
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className="user-img mr-3">
        <img src={icons.user} alt="" />
      </div>
      <div className="workflow-text-field">
        <TextField
          id="filled-multiline-flexible"
          value={comment}
          onChange={handleChange}
          variant="filled"
          className="workflow-add-comment"
          placeholder={t("Add comment to workflow")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SendIcon
                  className={isValid(comment) ? "svg-active" : "svg-grey"}
                  onClick={addComment}
                />
              </InputAdornment>
            ),
          }}
        />
        <div
          className={
            "input-char-count mt-1 " +
            (comment && !isValid(comment) ? "invalid" : "")
          }
        >
          {comment.length}/{maxChar} {t("character")}
        </div>
      </div>
    </div>
  );
};

export default WorkflowAddComment;
