export class TodoForm {
    public id?: number;
    public task: string = "";
    public caseId: number = 0;
    public questionId: number = 0;
    public deadline: string = "";
    public userId: number = 0;
    public status?: number;
    public name?: string;
    public email?: string;
    public created_at?: any;
}