import React, { useEffect, useContext } from 'react';
import './calender.page.scoped.scss'
import { CommonService } from '../../services/common/common.service';
import { useTranslation } from 'react-i18next';
import HeaderComponent from "../../components/header/header.component";
import { GlobalContext } from '../../store';

export default function CalenderPage() {
  const commonService = new CommonService();
  const [t] = useTranslation();
  commonService.setTitle(t("calender"));
  const { setHelpContent } = useContext(GlobalContext);

  useEffect(() => {
    setHelpContent("");
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <HeaderComponent />
      <div className="todo-wrapper">
        <img src="fallback.png" className="todo-img" alt='coming soon' />
        <h3 className='todo-title'>{t("coming soon")}...</h3>
      </div>
    </>
  );
}