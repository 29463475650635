import "./add-user.scoped.scss";
import React, { useState, useContext, useEffect, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import { CasesService } from "../../services/cases/cases.service";
import { CaseContext } from "../../store";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { icons } from "../../statics/icons";
import { CaseUser } from "../../types/case-user";

export const AddUserDialog = (props: any) => {
  const casesService = new CasesService();
  const { isEditable, isTodo, onSelectUser, user } = props;
  const emptyArr: any[] = [];
  const emptyObj: any = {};
  const { caseDetails, caseUsers, setCase, setCaseUsers } = useContext(
    CaseContext
  );
  const [saving, setSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [_typeahead, setTypeahead] = useState(emptyObj);
  const [users, setUsers] = useState(emptyArr);
  const [selectedUsers, setSelectedUsers] = useState(emptyArr);
  // Track the index of the highlighted menu item.
  const [activeIndex, setActiveIndex] = useState(-1);
  const labelKey: any = "name";
  const [t] = useTranslation();

  useEffect(() => {
    if (!isTodo) {
      setSelectedUsers(caseUsers);
    }
    // eslint-disable-next-line
  }, [caseUsers]);

  useEffect(() => {
    if (isTodo && user) {
      const todoUser = [];
      todoUser.push(user);
      setSelectedUsers(todoUser);
    }
    // eslint-disable-next-line
  }, []);
  const handleSearch = async (name: any) => {
    setSearchLoading(true);
    const usersResult = await casesService.searchUsers(
      caseDetails.id,
      name,
      isTodo
    );
    setUsers(usersResult);
    setSearchLoading(false);
  };

  const selectUser = (option: CaseUser) => {
    const selected: any[] = [...selectedUsers];
    const selectedUsersNames = [...selectedUsers.map((item: any) => item.name)];

    if (selectedUsersNames.includes(option.name)) {
      return;
    } else {
      selected.push(option);
      setSelectedUsers(selected);
      setIsChanged(true);
    }
    setTimeout(() => {
      _typeahead.clear();
      _typeahead.focus();
    }, 0);
  };

  const deleteUser = (option: CaseUser) => {
    const selected: any[] = [...selectedUsers];
    selected.splice(selected.indexOf(option), 1);
    setSelectedUsers(selected);
    setIsChanged(true);
  };

  const handleClose = (value: any) => {
    props.onClose(value);
    // reset state
    if (isTodo) {
      setTimeout(() => {
        setSelectedUsers([]);
      }, 200);
    }
    if (!value && !isTodo) {
      setTimeout(() => {
        setSelectedUsers([...caseUsers]);
      }, 200);
    }
  };

  const handleUpdate = async () => {
    if (isTodo) {
      onSelectUser(selectedUsers);
      handleClose(true);
    } else {
      if (saving) return;
      setSaving(true);
      let externalUsers = 0;
      let internalUsers = 0;
      const usersIds = selectedUsers.map((item: CaseUser) => {
        if (item.group === "internal") {
          internalUsers = internalUsers + 1;
        } else {
          externalUsers = externalUsers + 1;
        }
        return item.id;
      });
      await casesService.updateUsers(caseDetails.id, usersIds);
      setCase({ ...caseDetails, externalUsers, internalUsers });
      setCaseUsers([...selectedUsers]);
      setSaving(false);
      handleClose(true);
    }
  };

  const onKeyDown = useCallback(
    (e: any, cachedUsers: any) => {
      const code = e.keyCode || e.which;
      // Check whether the 'enter' key was pressed
      if (code === 13 && activeIndex !== -1) {
        selectUser(cachedUsers[activeIndex]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeIndex]
  );

  const handleFocus = (input: any) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 1);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-user"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isTodo ? (
            t("add_user_to_todo")
          ) : (
            <Trans
              i18nKey="add_user_to_case"
              values={{ internalId: caseDetails.internalId }}
            ></Trans>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isEditable && (
          <>
            <div className="user-search-icon">
              <img src={icons.search} alt="" />
            </div>
            <div className="typeahead-search">
              <AsyncTypeahead
                id="typeahead-search"
                isLoading={searchLoading}
                minLength={1}
                labelKey={labelKey}
                onSearch={handleSearch}
                options={users}
                placeholder={t("search for person")}
                promptText="Searching..."
                searchText="Searching..."
                ref={(ref) => {
                  setTypeahead(ref);
                  handleFocus(ref);
                }}
                onKeyDown={(e) => onKeyDown(e, [...users])}
                disabled={isTodo && selectedUsers.length === 1}
                renderMenuItemChildren={(option: CaseUser, props: any) => (
                  <div
                    className="user-search-option"
                    onClick={() => selectUser(option)}
                  >
                    <div className="user-info">
                      <div className="user-name trim">
                        <span>{option.name}</span>
                        <small>{option.email}</small>
                      </div>
                    </div>
                    <div className="user-role">{t(option.role)}</div>
                    <div className="user-type">{t(option.group)}</div>
                  </div>
                )}
              >
                {(state: any) => {
                  // Passing a child render function to the component exposes partial
                  // internal state, including the index of the highlighted menu item.
                  setActiveIndex(state.activeIndex);
                }}
              </AsyncTypeahead>
            </div>
          </>
        )}
        <div className="selected-users-container">
          {selectedUsers.map((selectedUser: CaseUser, index: any) => {
            return (
              <div className="selected-user" key={index}>
                <div className="user-info">
                  <div className="user-name">
                    <span>{selectedUser.name}</span>
                    <small>{selectedUser.email}</small>
                  </div>
                </div>
                <div className="user-role">{t(selectedUser.role)}</div>
                <div className="user-type">{t(selectedUser.group)}</div>
                {isEditable && (
                  <ClearIcon
                    className="user-delete"
                    onClick={() => deleteUser(selectedUser)}
                  />
                )}
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isEditable && (
          <>
            <Button
              variant="outlined"
              color="primary"
              className="btn-secondary-theme"
              onClick={handleClose.bind(null, false)}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn-primary-theme"
              onClick={handleUpdate}
              disabled={saving || !isChanged}
            >
              {saving ? (
                <CircularProgress color="inherit" />
              ) : (
                <>
                  <CheckIcon />
                  {isTodo ? t("select") : t("save")}
                </>
              )}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
