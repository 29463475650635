import React, { createContext, useReducer } from "react";
import CaseReducer from "../reducers/case.reducer";
import { Case } from "../../types/case";

const initialState: any = {
  caseDetails: new Case(),
  caseDueDate: new Date(),
  loading: false,
  communicationsLoading: false,
  workflowLoading: false,
  filesLoading: false,
  notesLoading: false,
  activeTab: 0,
  aiActivation: false,
  activationWorkflowAI: false,
  caseUsers: [],
  caseStatuses: [],
  caseCategories: [],
  lands: [],
  branches: [],
  caseAmounts: [],
  caseRelations: [],
  caseComments: [],
  caseQuestions: [],
  caseTotalQuestions: 0,
  caseTotalAnsweredQuestions: 0,
  completedStatusId: 4,
  caseFiles: [],
  caseNotes: [],
  caseDetailsUpdated: false,
};

export const CaseContext = createContext(initialState);
export const CaseProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(CaseReducer, initialState);

  function setCase(caseDetails: Case) {
    dispatch({
      type: "SET_CASE",
      payload: { caseDetails },
    });
  }

  function setCaseDueDate(caseDueDate: Case) {
    dispatch({
      type: "SET_CASE_DUE_DATE",
      payload: { caseDueDate },
    });
  }

  function setCaseUsers(caseUsers: any[]) {
    dispatch({
      type: "SET_CASE_USERS",
      payload: { caseUsers },
    });
  }

  function setCaseAmounts(caseAmounts: any[]) {
    dispatch({
      type: "SET_CASE_AMOUNTS_OF_DAMAGE",
      payload: { caseAmounts },
    });
  }

  function setCaseCategories(caseCategories: any[]) {
    dispatch({
      type: "SET_CASE_Categories",
      payload: { caseCategories },
    });
  }

  function setLands(lands: any[]) {
    dispatch({
      type: "SET_LANDS",
      payload: { lands },
    });
  }

  function setBranches(branches: any[]) {
    dispatch({
      type: "SET_BRANCHES",
      payload: { branches },
    });
  }

  function setCaseRelations(caseRelations: any[]) {
    dispatch({
      type: "SET_CASE_RELATIONS",
      payload: { caseRelations },
    });
  }
  function setCaseComments(caseComments: any[]) {
    dispatch({
      type: "SET_CASE_COMMENTS",
      payload: { caseComments },
    });
  }

  function setCaseQuestions(caseQuestions: any[]) {
    dispatch({
      type: "SET_CASE_QUESTIONS",
      payload: { caseQuestions },
    });
  }

  function setCaseTotalQuestions(totalQuestions: any[]) {
    dispatch({
      type: "SET_CASE_TOTAL_QUESTIONS",
      payload: { totalQuestions },
    });
  }

  function setCaseTotalAnsweredQuestions(totalAnsweredQuestions: any[]) {
    dispatch({
      type: "SET_CASE_TOTAL_ANSWERED_QUESTIONS",
      payload: { totalAnsweredQuestions },
    });
  }

  function setCaseStatuses(caseStatuses: any[]) {
    dispatch({
      type: "SET_CASE_STATUSES",
      payload: { caseStatuses },
    });
  }

  function setLoading(loading: boolean) {
    dispatch({
      type: "SET_LOADING",
      payload: { loading },
    });
  }

  function setCommunicationsLoading(communicationsLoading: boolean) {
    dispatch({
      type: "SET_COMMUNICATIONS_LOADING",
      payload: { communicationsLoading },
    });
  }

  function setWorkflowLoading(workflowLoading: boolean) {
    dispatch({
      type: "SET_WORKFLOW_LOADING",
      payload: { workflowLoading },
    });
  }

  function setActiveTab(activeTab: number) {
    dispatch({
      type: "SET_ACTIVE_TAB",
      payload: { activeTab },
    });
  }

  function setAIActivation(aiActivation: boolean) {
    dispatch({
      type: "SET_AI_ACTIVATION",
      payload: { aiActivation },
    });
  }

  function setActivationWorkflowAI(activationWorkflowAI: number) {
    dispatch({
      type: "SET_ACTIVATION_WORKFLOW_AI",
      payload: { activationWorkflowAI },
    });
  }

  function setCaseFiles(caseFiles: any[]) {
    dispatch({
      type: "SET_CASE_FILES",
      payload: { caseFiles },
    });
  }

  function setFilesLoading(filesLoading: boolean) {
    dispatch({
      type: "SET_FILES_LOADING",
      payload: { filesLoading },
    });
  }

  function setCaseNotes(caseNotes: any[]) {
    dispatch({
      type: "SET_CASE_NOTES",
      payload: { caseNotes },
    });
  }

  function setNotesLoading(notesLoading: boolean) {
    dispatch({
      type: "SET_NOTES_LOADING",
      payload: { notesLoading },
    });
  }

  function setCaseDetailsUpdated(caseDetailsUpdated: boolean) {
    dispatch({
      type: "SET_CASE_DETAILS_UPDATED",
      payload: { caseDetailsUpdated },
    });
  }

  return (
    <CaseContext.Provider
      value={{
        ...state,
        setCommunicationsLoading,
        setLoading,
        setWorkflowLoading,
        setCaseDueDate,
        setCase,
        setActiveTab,
        setAIActivation,
        setActivationWorkflowAI,
        setCaseStatuses,
        setCaseUsers,
        setCaseAmounts,
        setCaseCategories,
        setLands,
        setBranches,
        setCaseRelations,
        setCaseComments,
        setCaseQuestions,
        setCaseTotalQuestions,
        setCaseTotalAnsweredQuestions,
        setCaseFiles,
        setFilesLoading,
        setCaseNotes,
        setNotesLoading,
        setCaseDetailsUpdated,
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};
