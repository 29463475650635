import React, { useContext, useState } from "react";
import "./files-tab.component.scoped.scss";
import { FilesTimelineComponent } from "../files-timeline/files-timeline.component";
import { FilesUploadSectionComponent } from "../files-upload-section/files-upload-section.component";
import FilesDropZone from "../files-drop-zone/files-drop-zone.component";
import { CaseContext } from "../../store";
import { useTranslation } from "react-i18next";

export const FilesTabComponent = (props: any) => {
  const { caseDetails } = useContext(CaseContext);
  const [t] = useTranslation();

  const [files, setFiles] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  return (
    <FilesDropZone onDropFiles={setFiles} isSaving={isSaving}>
      <div className="theme-container files-tab">
        <FilesTimelineComponent />
      </div>
      {caseDetails.statusKey !== "COMPLETED" && (
        <div className="sticky-container">
          <div className="theme-container">
            <FilesUploadSectionComponent
              tab="files"
              droppedFiles={files}
              label={t(
                "add file and description to document internal processing"
              )}
              onUploadingFiles={setIsSaving}
            />
          </div>
        </div>
      )}
    </FilesDropZone>
  );
};
